.wrapper{
    width: 100%;
    // height: 360px;
    background-color: white;
    display: flex;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    margin: 20px 0;
    
    
}
.container{
    width: 100%;
    padding: 10px 10px 2px;
}
.title{
    font-size: 1.9rem;
    line-height: 1;
    font-weight: 600;
    color: #212529;
    margin-bottom: 14px;
}
.image{

    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0 5px;
    &:hover{
        cursor: pointer;
    }
}
@media (max-width:692px){
    .container{
        padding: 0;
    }
    .title{
        font-size: 1.6rem;
        margin-left: 10px;
    }
    .swiper-button-next, .swiper-button-prev {
        display: none;
    }
}
