.wrapper{
    margin-top: 100px;
    width: 100%;
    // margin-bottom: 10px;
    background-color: var(--b-color);
    
}
.container{
    width: 100%;
    
}
.direct{
    ol{
        margin: 10px 0;
        display: flex;
        list-style: none;
        li{
           
            line-height: 1;
            font-size: 14px;
            margin-right: 4px;
            color: #6c757d;
        }
        .direct-active{
            color: #0275d8;
        }
    }
}
.slider1{
    width: 100%;
    border-radius: 6px;
}
.body-slider{
    border-radius: 6px;
}
.image{
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
}
@media (max-width : 400px){
    .container{
        margin-top: 24px;
    }
}