.wrapper{
    width: 100%;
    height: calc(100vh - 44px);
    background-color: #e6f3f7;  
}
.title{
    width: 100%;
    text-align: center;
    height: 80px;
    font-weight: 600;
    font-size: 2.3rem;
    color: blue;
    line-height: 80px;
}
.body-message{
    width:100% ;
    background-color: #e6f3f7;
    height: calc(100% - 80px);
    flex: 1;
    padding: 0px 10px;
    display: flex;
    border-radius: 4px;
    gap: 10px;
}
.left{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 30%;
    background-color: white;
    border-radius: 8px;
    
}
.left-title{
    display: flex;
    height: 50px;
    align-items: center;
}
.left-input-body{
    width: 100%;
    height: 70%;
    border-radius: 20px;
    margin: 10px 8px;
    border: 1px solid rgba(24,24,25,0.2);
    display: flex;
    align-items: center;
}
.left-input-icon{
    font-size: 2.0rem;
    margin: 0 8px ;
}
.left-input{
    width: 80%;
    height: 100%;
    font-size: 1.6rem;
}
.left-list-user{
    height: calc(100% - 50px);
    border-top: 2px solid rgba(24,24,24,0.2);
    padding: 10px 0px 0;
    margin: 0 0 10px 0;
    display: flex;
}
.left-list{
    width: 100%;
    margin: 0 0 0px 0;
    height: 100%;
    overflow-y: scroll;
}
.item{
    display: flex;
    height: 78px;
    padding: 8px 0;
    background-color: #fff;
    align-items: center;
    &:hover{
        cursor: pointer;
        background-color:#f5f6f7 ;
    }

}
.item-name{
    font-weight: 500;
}
.item-text{
    font-size: 1.3rem;
    color: #838888;
}
.item-active{
    background-color: #f5f6f7;
}
.item-avatar{
    margin-left: 8px;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    object-fit: cover;
}
.item-body{
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
    
}
.item-body-info{
    display: flex;
    flex-direction: column;
    flex: 1;
    // background-color: red;
    padding:0 14px 0 ;
}
.item-status{
    position: absolute;
    right: 10px;
    background-color: red;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

// right
.right{
    position: relative;
    width: 70%;
    background-color: #fff;
    border-radius: 8px;
    height: 100%;
    // background-color: pink;
}
.r-header{
    width: 100%;
    height: 70px;
    // background-color: greenyellow;
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba(24,24,25,0.2);
}
.r-header-avatar{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin: 0 12px 0 10px;
    object-fit: cover;
}
.r-header-info{
    font-size: 1.6rem;
    font-weight: 600;
    p{
        font-weight: 300;
        font-size: 1.2rem;
        color: #6e7777;
    }
}
.r-body{
    width: 100%;
    height: calc(100% - 130px);
    overflow-y: scroll;
}
.r-item{
    width: 70%;
    
    display: flex;
    flex-direction: column;
    margin-top: 10px;
   
    .r-item-info{
        display: flex;
        align-items: flex-end;
    }
    .r-item-image{
        width: 32px;
        height: 32px;
        margin: 0 10px;
    }
    .r-item-avatar{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        
    }
    .r-item-body{
        div{
            background-color: rgb(228,230,235);
            border-radius: 10px;
            padding: 6px 10px;
        }
    }
    p{
        text-align: end;
    }
}
.r-item-own{
    width: 100%;
    align-items: flex-end;
    .r-item-info{
        justify-content: flex-end;
    }
    .r-item-body{
        width: 70%;
        margin-right: 10px;
       
        div{
            background-color: var(--header-b-color);
            padding-right: 30px;
            text-align: end;
        }
    }
    .r-item-image{
        display: none;
    }
    p{
        width: 70%;
        text-align: start !important;
    }
    
}
// bottom
.r-bottom{
    border-top: 2px solid rgba(24,24,25,0.2);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}
.r-bottom-input{
    flex: 1;
    // background-color: red;
    border: 1px solid rgba(24,24,24,0.2);
    border-radius: 20px;
    height: 40px;
    margin-left: 10px;
    padding: 0 14px;
    font-size: 1.5rem;
}

.r-bottom-icon{
    font-size: 2.4rem;
    margin: 0 10px;
    &:hover{
        transform: rotate(45deg);
        cursor: pointer;
        opacity: 0.7;
    }
}