.wrapper{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.container {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color);
    width: var(--default-layout-width);
    align-items: center;
    
}
.header-detail{
    width: 100%;
}
.direct{
    ol{
        margin: 10px 0;
        display: flex;
        list-style: none;
        li{
           
            line-height: 1;
            font-size: 14px;
            margin-right: 4px;
            color: #6c757d;
            position: relative;
            &:hover{
                &::before{
                    transform: scaleX(1);
                    cursor: pointer;
                    transition: transform .5s ease,-webkit-transform .5s ease;
                }
            }
        }
        li::before{
            content: "";
            position: absolute;
            left: 0;
            bottom: -4px;
            width: 100%;
            height: 1px;
            background-color: #0168fa;
            transition: transform .5s ease,-webkit-transform .5s ease;
            transform: scaleX(0);
        }
        .direct-active{
            color: #0275d8;
        }
    }
}
// ìno
.header-info{
    display: flex;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 16px;
    -webkit-box-shadow: inset 0 -1px 0 0 #dee2e6;
    box-shadow: inset 0 -1px 0 0 #dee2e6;
    min-height: 57px;
    justify-content: space-between;
    align-items: flex-end;
}
.body{
    width: 776px;
    margin: 0 auto;
    padding-bottom: 20px;
    background-color: red;
    min-height: 200px;
    box-shadow: 0 1px 4px rgba(10,10,10,.15);
    background: #fff;
    display: flex;
    flex-direction: column;
}
// header
.card-title{
    padding: 12px 24px;
    box-shadow: inset 0 -1px 0 #edeeef;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-title-left{
    color: #32373d;
    font-weight: 500;
}
.card-title-right{
    color: #0664f9;
    font-size: 14px;
    line-height: 14px;
}
.card-title-right{
    display: flex;
    align-items: center;
    color: blue;
    position: relative;
    &:hover{
        cursor: pointer;
        &::before{
            transition: transform .5s ease,-webkit-transform .5s ease;
            transform: scaleX(1);
        }
    }
}
.card-title-right::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 1px;
    background-color: #0168fa;
    transition: transform .5s ease,-webkit-transform .5s ease;
    transform: scaleX(0);
}
// info
.card-info{
    display: flex;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(10,10,10,.09);
}
.info-image-body{
    margin-right: 8px;
    width: 80px;
    height: 80px;
}
.info-image{
    width: 80px;
    height: 80px;
    object-fit: cover;
}
.info-name{
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #32373d;
}
.info-price{
    display: flex;
    font-size: 14px;
    line-height: 20px;
    color: #444b52;
    font-weight: 400;
    p{
        color: #cb1c22;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-left: 8px;
    }
}
.info-detail{
    color: #0664f9;
    font-size: 14px;
    line-height: 14px;
    margin: 10px 0;
}
.info-detail{
    display: flex;
    align-items: center;
    color: blue;
    position: relative;
    &:hover{
        cursor: pointer;
        &::before{
            transition: transform .5s ease,-webkit-transform .5s ease;
            transform: scaleX(1);
        }
    }
}
.info-detail::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 1px;
    background-color: #0168fa;
    transition: transform .5s ease,-webkit-transform .5s ease;
    transform: scaleX(0);
}
// tab top
.tab-top{
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    align-items: center;
    
}
.tab-title{
    font-size: 16px;
    line-height: 24px;
    color: #32373d;
    font-weight: 500;
}
.tab-select{
    display: flex;
    padding: 0px 24px;
    justify-content: center;
    gap: 6px;
    
}
.select-item{
    width: 33%;
    padding: 14px 6px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    background: #f8f9fa;
}
.select-item-active{
    background: #edeeef;
}
.select-item-body{
    display: flex;
    align-items: center;
    color: #444b52;
    font-size: 1.4rem;
    line-height: 20px;
}
.tab-select-label{
    font-size: 1.4rem;
    line-height: 20px;
    margin-left: 6px;
}
.tab-select-text{
    color: #6a737a;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
.tab-select-choose{
    width: 100%;
    display: flex;

}
.mr-10{
    margin-right: 16px;
}
.select-item-body-border{
        border: 1px solid #cbd1d6; 
        margin-right: 8px;
        margin-bottom: 8px;
        color: #6a737a;
        background: #fff;
        font-size: 16px;
        line-height: 16px;
        padding: 6px 18px;
        border-radius: 6px;
}
// ìno
.tab-info{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 10px 24px;
}
.tab-input-body{
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid rgba(10,10,10,.19);
    border-radius: 6px;
}
.tab-input{
    height: 100%;
    width: 100%;
    font-size: 1.4rem;
    padding: 8px 10px;
    border-radius: 6px;
}
.tab-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.btn-pay{
    color: #fff;
    background: #cb1c22;
    border-radius: 6px;
    font-size: 20px;
    line-height: 20px;
    transition: all .3s cubic-bezier(0,0,.4,1);
    border: 1px solid transparent;
    padding: 16px 28px;
    text-transform: uppercase;
    &:hover{
        background: #bd1319;
        border: 1px solid #bd1319;
    }
}
.tab-privacy{
    color: #939ca3;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

@media (max-width: 692px) {
    .wrapper{
        width: 100%;
        padding: 10px 8px;
    }
    .container{
        width: 100%;
        
    }
    .card-title{
        padding: 10px 8px;
        font-size: 1.2rem;
    }
    .card-info{
        padding: 12px 10px;
    }
    .tab-top{
        padding: 6px 8px;
        
    }
    .tab-title{
        font-size: 1.2rem;
    }
    .tab-select{
        padding: 0px 8px;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        gap: 4px;
    }
    .card-title-right,.info-detail{
        font-size: 1rem;
    }
    .body{
        width: 100%;
    }
    .select-item{
        padding: 8px 0px;
        width: 48%;
        
    }
    .tab-select-label{
        font-size: 1.2rem;
        font-weight: 500;
    }
    .tab-select-text{
        font-size: 1rem;
    }
    .tab-select-choose{
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 4px;
    }
    // .select-item-body{
    //     width: 48%;
    // }
    .select-item-body-border{
        margin: 0;
    }
    .tab-info{
        width: 100%;
        padding: 8px ;
    }
    .tab-btn{
        margin-top: 10px;
    }
    .btn-pay{
        font-size: 1.2rem;
        padding: 8px 12px;
    }
    .tab-privacy{
        font-size: 1.1rem;
    }
    .tab-input{
        font-size: 1.2rem;
    }
    .tab-input-body{
        margin-bottom: 6px;
    }
}