.wrapper{
    width: 100%;
    // height: 360px;
    // background-color: white;
    display: flex;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    margin: 20px 0;
    // padding: 5px 5px 0 5px;
    gap:8px;
}
.image{
    border-radius: 10px;
    cursor: pointer;
}
@media (max-width: 440px) {
    .wrapper{
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        margin: 0;
    }
    .image{
        width: 100%;
    }
}
