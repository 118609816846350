.wrapper{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    // background-color: var(--b-color);
    align-items: center;
    min-height: 100vh;
    margin-bottom: 20px;
}
.container {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color);
    width: var(--default-layout-width);
    align-items: center;
    
}
.header-detail{
    width: 100%;
}
.direct{
    ol{
        margin: 10px 0;
        display: flex;
        list-style: none;
        li{
           
            line-height: 1;
            font-size: 14px;
            margin-right: 4px;
            color: #6c757d;
            position: relative;
            &:hover{
                &::before{
                    transform: scaleX(1);
                    cursor: pointer;
                    transition: transform .5s ease,-webkit-transform .5s ease;
                }
            }
        }
        li::before{
            content: "";
            position: absolute;
            left: 0;
            bottom: -4px;
            width: 100%;
            height: 1px;
            background-color: #0168fa;
            transition: transform .5s ease,-webkit-transform .5s ease;
            transform: scaleX(0);
        }
        .direct-active{
            color: #0275d8;
        }
    }
}
// ìno
.header-info{
    display: flex;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 16px;
    -webkit-box-shadow: inset 0 -1px 0 0 #dee2e6;
    box-shadow: inset 0 -1px 0 0 #dee2e6;
    min-height: 57px;
    justify-content: space-between;
    align-items: flex-end;
}
.header-info-name{
    font-weight: 500;
}
.header-info-right{
    display: flex;
    align-items: center;
    p{
        color: #0168fa;
        cursor: pointer;
        font-size: 1.2rem;
        margin-left: 6px;
        color: blue;
        line-height: 1.2rem;
        
    }
    .header-info-vote{
        color: blue;
        border-right: 1px solid red;
        padding-right: 6px;
    }
    
}
.header-info-icon{
    color: #ea9d02;
}
// body
.body{
    width: 100%;
    display: flex;
}
.body-left{
    width: 50%;
    

}
// markdown
.body-markdown{
    width: 100%;
    height: 500px;
    // background-color: red;
    display: flex;
    gap: 20px;
    margin: 20px 0;
    overflow: hidden;
    position: relative;
}
.body-markdown-full{
    height: 100%;
}
.mark-left{
    width: 60%;
    padding: 16px 20px;
    color: #212529;
    font-weight: 400;
    box-shadow: inset 0 -1px 0 0 #e9ecef;
    border: 1px solid;
    border-color: #e9ecef #e9ecef transparent #e9ecef;
    border-radius: 4px 4px 0 0;
    
    background-color: #f8f9fa;
    font-size: 1.6rem;
    h5,h6{
        font-size: 1.6rem;
        font-weight: 400;
    }
    img{
        width: 100%;
        margin: 6px 0;
    }
}
.mark-right{
    width: 40%;
    padding: 16px 20px;
    color: #212529;
    font-weight: 400;
    box-shadow: inset 0 -1px 0 0 #e9ecef;
    border: 1px solid;
    border-color: #e9ecef #e9ecef transparent #e9ecef;
    border-radius: 4px 4px 0 0;
    
    background-color: #f8f9fa;
    p{
        font-size: 1.6rem;
        margin: 0 0 10px;
        font-weight: 500;
    }
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
}
.see-more{
    width: 60%;
    position: absolute;
    bottom: 0px;
    text-align: center;
    cursor: pointer;
    background-image: linear-gradient(to bottom,rgba(255,255,255,0),#fff 98%,rgba(0,0,0,0));
    background-color: red;
    p{
        position: absolute;
        bottom: -10px;
        right: 50%;
        transform: translateX(50%);
        display: inline;
        // border: solid 1px rgb(241, 211, 211);
        background-color: rgb(245, 234, 234);
        padding: 10px 0;
        font-size: 1.2rem;
        border-radius: 8px;
        color: #495057;
        padding: 8px 10px;
        margin-bottom: 10px;
        font-size: 1.6rem;
        font-weight: 500;
        color: rgb(224, 53, 53);
        transition: all .3s ease;
       &:hover{
        // color: blue;
        background-color:  rgb(227, 179, 179);
       }
       
    }
}
// product
.product{
    padding-right: 15px;
    width: 100%;
    // height: 460px;
    
    border: solid 1px white;
    
    
}
.product-image{
    position: relative;
    width: 100%;
    background-color: var(--border-image-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 390px;
    border-radius: 10px;
    border-top: 40px solid var(--border-image-color);
    border-left: 2px solid var(--border-image-color);
    border-right: 2px solid var(--border-image-color);
    border-bottom: 2px solid var(--border-image-color);
    margin-bottom: 20px;
    
}
.image-footer{
    
    width: 100%;
    height: 20px;
    background-color: var(--border-image-color);
    position: relative;
    border-radius: 10px;

}
.image-footer::after{
    margin-top: 5px;
    content: "";
    position: absolute;
    top: -26px;
    left: 0px;
    border-width: 26px 290px 0;
    border-style: solid;
    border-color: transparent var(--border-image-color) var(--border-image-color) var(--border-image-color) ;
}
.image-footer-btn{
    position: absolute;
    top: -33px ;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    font-size: 2.4rem;
    font-weight: 500;
    background-color: var(--border-image-color);
    z-index: 1;
    padding: 4px 12px ;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-left: 5px solid #e6c209;
    border-top: 5px solid #e6c209;
    border-right: 5px solid #e6c209;
    color: white;
    p{
        margin-left: 6px;
        color: #fbee35;
    }

}


.title-sale-product{
    position: absolute;
    top: -34px;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
}
.p-image-body{
    width: 100%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    // align-items: center;
    
}
.p-image{
    margin-top: 15px;
    height: 80%;
    object-fit: cover;
}

.product-sale{
    bottom: 10px;
    left: 0px;
    position: absolute;
    font-size: 13px;
    padding: 0 8px;
    line-height: 24px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: inline-block;
    background-color: var(--border-image-color);
    color: white;
}
.product-pay{
    bottom: 42px;
    left: 0px;
    position: absolute;
    font-size: 13px;
    padding: 0 8px;
    line-height: 24px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: inline-block;
    background-color: #21aefa;
    color: white;
}
// info
.product-info{
    width: 100%;
    margin-top: 8px;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.product-info-title{
    font-size: 1.7rem;
    font-weight: 600;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    &:hover{
        cursor: pointer;
        color: blue;
    }
}
// more info 
.more-info{
    padding: 15px 20px;
    border-radius: 4px;
    border: solid 1px #e9ecef;
    background-color: #f8f9fa;
    margin-bottom: 16px;
}
.more-info-item{
    display: flex;
    flex-direction: column;
    
    margin-bottom: 4px;
    span, p{
        display: flex;
        align-items: center;
        margin-right: 8px;
    }
}
.more-info-icon{
    margin-right: 4px;
}
.more-info-sale-up{
    margin: 6px 0;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.31;
    color: #555;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}
.more-info-detail{
    position: relative;
    color: #0168fa;
    font-size: 1.2rem;
    margin-top: 8px;
    font-weight: 500;
    
    display: inline-block;
    &:hover{
        
        cursor: pointer;
        &:before{
            transform: scaleX(1);
            cursor: pointer;
            transition: transform .5s ease,-webkit-transform .5s ease;
        }
        
    }

}
.more-info-detail:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background-color: #0168fa;
    transition: transform .5s ease,-webkit-transform .5s ease;
    transform: scaleX(0);
    
}


// driver
.product-driver{
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    span{
        margin: 0 20px 0 8px;
    }
}
// -----right-----------
.body-right{
    width: 50%;
    padding-left: 15px;
}
.price-body{
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.price-left{
    font-weight: 500;
    color: #cb1c22;
    margin-right: 10px;
    font-size: 3rem;
    span{
        font-size:  1.8rem;
        font-weight: 400;
        color: #99a2aa;
        text-decoration:line-through ;
        margin-left: 10px;
    }
}
.price-right{
    font-size: 1.2rem;
}
.option-body{
    margin-bottom: 16px;
    border-radius: 4px;
    display: flex;
}
.option-item{
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.3rem;
    padding: 4px 0;
    cursor: pointer;
    &:hover{
        background-color: #edeeef;
    }
}
.price-radio{
    font-weight: 600;
    display: flex;
    align-items: center;
    label{
        margin-left: 4px;
    }
}
.radio-active{
    background: #edeeef;
}
// promo
.box-promo{
    background-color: #f8f9fa;
    margin-bottom: 16px;
    

}
.box-promo-item{
    padding: 14px 10px;
    color: #212529;
    font-weight: 400;
    -webkit-box-shadow: inset 0 -1px 0 0 #e9ecef;
    box-shadow: inset 0 -1px 0 0 #e9ecef;
    border: 1px solid;
    border-color: #e9ecef #e9ecef transparent #e9ecef;
    border-radius: 4px 4px 0 0;
    font-size: 1.4rem;
    p{
        font-weight: 500;
    }

}
.promo-radio{
    display: flex;
    padding: 4px 10px;
    align-items: center;
    label{
        margin-left: 8px;
    }
}

.box-promo-item-uu-dai{
    padding: 4px 10px;
    font-weight: 500;
    background-color: #e9ecef;
    display: inline-block;
    border-radius: 0 0 4px 0;
}
// btn 
.btn{
    display: flex;
    flex-direction: column;
}
.btn-buy-now{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    padding: 4px 0;
    font-size: 1.2rem;
    border-radius: 6px;
    color: #fff;
    background: #cb1c22;
    outline: 0;
    font-weight: 500;
    &:hover{
        background: #ae1427;
        cursor: pointer;
    }
}
.btn-buy-now-big{
    font-size: 1.7rem;
}
.btn-gop{
    display: flex;
    justify-content: space-between;
    gap:6px;
}
.btn-gop-item{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0664f9;
    border-radius: 6px;
    color: white;
    &:hover{
        background: #0050d7;
        cursor: pointer;
    }
}
// connect
.connect{
    margin-top: 8px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
}
.connect-left{
    span{
        color: red;
        margin: 0 2px;
        &:hover{
            cursor: pointer;
            color: #182c4a;
        }
    }
}
.connect-right{
    display: flex;
    align-items: center;
    color: blue;
    position: relative;
    &:hover{
        cursor: pointer;
        &::before{
            transition: transform .5s ease,-webkit-transform .5s ease;
            transform: scaleX(1);
        }
    }
}
.connect-right::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background-color: #0168fa;
    transition: transform .5s ease,-webkit-transform .5s ease;
    transform: scaleX(0);
}
// question
.question{
    background-color: #fff;
    background-clip: border-box;
    -webkit-box-shadow: 0 0 3px 0 #dee2e6;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    width: 100%;
    margin: 20px 0;
}
.question-title{
    color: #212529;
    font-size: 1.8rem;
    font-weight: 600;
    padding: 12px 20px;
}
.question-item{
    box-shadow: inset 0 1px 0 0 #dee2e6;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.question-content{
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    
    
}
.question-icon{
    font-size: 2.2rem;
    color: #ced4da;
    margin-right: 6px;

}
.question-more-info{
    margin-top: 6px;
    text-align: left;
    margin-bottom: 11px;
    color: rgb(73,80,87);
    font-size: 1.4rem;
    padding-right: 10px;
}
// commment
.comment{
    background-color: #fff;
    background-clip: border-box;
    -webkit-box-shadow: 0 0 3px 0 #dee2e6;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    width: 100%;
    margin: 20px 0;
    
}
.comment-title{
    padding: 16px 20px;
    box-shadow: inset 0 -1px 0 #edeeef;
    font-size: 2rem;
    font-weight: 500;
}
.comment-box{
    padding: 16px 20px;
    width: 100%;
    
    position: relative;
}
.comment-text-input{
    width: 100%;
    height: 74px;
    padding: 10px;
    border-radius: 6px;
}
.comment-send-btn{
    display: inline-block;
    text-align: center;
    color: #fff;
    background: #cb1c22;
    outline: none;
    font-size: 16px;
    line-height: 16px;
    border-radius: 6px;
    padding: 0 16px;
    height: 36px;
    line-height: 36px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 38px;
    &:hover{
        cursor: pointer;
        opacity: 0.9;
    }
}
.count-comment{
    padding: 8px 20px;
    background: #f8f9fa;
    box-shadow: inset 0 1px 0 #edeeef;
    color: #444b52;
    font-weight: 400;
}
.list-comment{
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
}
.user-comment{
    display: flex;
    margin-top: 10px;
    &:hover{
        .icon-remove{
            display: block;
        }
    }
}
.user-avatar{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
}
.user-info{
    margin-left: 12px;
}
.user-name{
    font-weight: 500;
    margin-top: 8px;
}
// delete icon
.icon-remove{
    display: none;
    position: relative;
    margin-top: 8px;
    p{
        display: none;
        padding: 6px 10px;
        border: 1px solid rgba(24,24,24,0.1);
        background-color: rgb(202, 199, 199);
        border-radius: 6px;
        position: absolute;
        left: -50%;
        cursor: pointer;
    }
}
.show-delete{
    display: block !important;
}
.delete-icon{
    // position: absolute;
    // top: 50px;
    cursor: pointer;
    

}
.text-comment{
    margin-top: 4px;
    font-size: 1.4rem;
}
.user-info-time{
    display: flex;
    font-size: 1.2rem;
    color: blue;
    p{
        color: rgb(70, 68, 68);
        margin-right: 6px;
    }
    span{
        margin: 0 2px;
        cursor: pointer;
        position: relative;
        &:hover{
            &:before{
                transform: scaleX(1);
                    cursor: pointer;
                    transition: transform .5s ease,-webkit-transform .5s ease;
            }
        }
    }
    span::before{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        height: 1px;
        background-color: #0168fa;
        transition: transform .5s ease,-webkit-transform .5s ease;
        transform: scaleX(0);
    }

}
@media (max-width: 1225px) {
    .container{
        width: 100%;
        padding: 0 6px;
    }
    .header-info{
        padding-right: 6px;
    }
    .price-body{
        padding-right: 6px;
    }
}
@media (max-width: 1210px) {
    .image-footer::after{
        display: none;
    }
    .container{
        margin-top: 10px;
    }
}
@media (max-width: 750px) {
    .body{
        flex-direction: column;
        align-items: center;
    }
    .body-left{
        width: 100%;
    }
    .body-right{
        width: 100%;
        padding: 0 8px 0 6px;
    }
}
@media (max-width: 460px) {
    .header-info-right{
        display: none;
    }
    .header-info-name{width: 100%;text-align: center;}

}

// pagination
.btn-cmt{
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
.s-nav-page{
    display: flex;
    align-items: center;

}
.s-nav-page-btn{
    padding: 4px 12px;
    
    background-color: #fff;
    border: 1px solid #cbd1d6;
    font-size: 1.2rem;
    line-height: 20px;
    border-radius: 4px;
    margin-left: 6px;
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        background-color: #1bdde7;
        color: white;
    }
}

.disable{
    background-color: rgb(216, 214, 214);
    &:hover{
        background-color:  rgb(216, 214, 214);
        color: black;
        cursor:default;
    }
}
.wrapper{
    margin-bottom: 0;
}
@media (max-width:692px){
    .wrapper{
        margin-bottom: 0;
    }
    .header-info{
        flex-direction: column;
        align-items: flex-start;
    }
    .header-info-name{
        font-size: 1.9rem;
    }
    .header-info-right{
        font-size: 1rem;
    }
    .image-footer-btn{
        font-size: 2rem;
    }
    .more-info{
        padding: 10px 6px;
        font-size: 1.5rem;
    }
    .product{
        padding: 0;
    }
    .product-driver{
        font-size: 1.2rem;
    }
    
    .price-left{
        font-size: 2.4rem;
    }
    .price-right{
        display: none;
    }
    .box-promo{
        font-size: 1.2rem;
    }
    .promo-radio{
        padding: 4px 0;
        font-size: 1.2rem;
    }
    .box-promo-item{
        padding: 6px;
    }
    .body-right{
        padding: 0;
    }
    .btn-gop-item{
        font-size: 1rem;
    }
    .btn-buy-now-big{
        font-size: 1.4rem;
    }
    .connect{
        flex-direction: column;
    }
    .connect-left,.connect-right{
        font-size: 1.2rem;
    }
    .body-markdown{
        flex-direction: column-reverse;
        height: auto;
        margin: 0;
    }
    .mark-right{
        width: 100%;
        padding: 10px 4px;
        font-size: 1.4rem;
    }
    .mark-left{
        width: 100%;
        font-size: 1.4rem;
        padding: 10px 4px;
    }
    .see-more{
        p{
            transform: translateX(100%);
        }
    }
    .question-title{
        font-size: 1.5rem;
        padding: 10px 4px;
    }
    .question-item{
        padding: 8px ;
        font-size: 1.2rem;
    }
    .question-content{
        font-size: 1.4rem;
    }
    .question-more-info{
        font-size: 1.2rem;
    }
    .comment-title{
        font-size: 1.6rem;
        padding: 10px 4px;
    }
    .comment-box{
        padding: 10px 0;
    }
    .comment-send-btn{
        right: 6px;
        font-size: 1.2rem;
        padding: 0px 6px;
    }
    .user-avatar{
        width: 32px;
        height: 32px;
    }
    .user-name{
        font-size: 1.4rem;
    }
    .list-comment{
        padding: 10px 4px;
    }
    .btn-cmt{
        margin: 8px 0;
    }
    .comment{
        margin: 0;
    }
    .count-comment{
        padding: 8px 6px;
        font-size: 1.4rem;
    }
}