.wrapper{
    width: 100%;
    padding: 0 10px;
    align-items: center;
    padding: 0 auto;
   

}
.container{
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    
}
.title{
    width: 100%;
   color: blue;
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    margin: 20px;
}
.btn{
    background-color: rgb(92, 241, 229);
    &:hover{
        background-color: red;
        color: white;
    }
}
.table{
    width: 100%;
    border-radius: 6px;
    
    border-collapse: collapse;
    padding: 0 20px;
    margin: 20px;
    text-align: center;
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #04AA6D;
        color: white;
        padding-left: 6px;
        border: 1px solid red;
        text-align: center;
      }
    //   tr{
    //     width: 1120px;
    //   }
    td, #customers th {
        border: 1px solid red;
        padding: 8px;
      }
      tr:nth-child(even){background-color: #f2f2f2;}
      tr:nth-child(even){background-color: #f2f2f2;}
      tr:hover {background-color: rgb(136, 243, 225);}
      
  }
.table-action{
    div{
        display: flex;
        justify-content: space-around;}
    
}
.actions-icon{
    font-size: 2rem;
    &:hover{
        cursor: pointer;
        color: red;
    }
}
  
  