.wrapper{
    
    width: 100%;
    min-height: 360px;
    background-color: white;
    display: flex;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    margin: 50px 0 20px;
    // padding: 5px 5px 0 5px;
    gap:4px;
    border: 1px solid rgb(4, 235, 243);
}
.container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    
}
.flash{
    position: absolute;
    display: flex;
    width: 520px;
    height: 66px;
    left: 50%;
    top: -33px;
    transform: translateX(-50%);
    
    // background: linear-gradient(213.94deg,#5ff0f0 16.68%,#40d1f5 50.45%,#44f3ea 66.83%);
    background: linear-gradient(213.94deg,#f0ebae 16.68%,#dfb45c 40.45%,#fee8a0 66.83%);
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    h2{
        font-size: 3.0rem;
        line-height: 1;
        color: #0725ec;
        margin-right: 16px;
        text-transform: uppercase;
        border-right: 2px solid #0725ec;
        padding-right: 8px;
    }
    span{
        font-size: 2rem;
        line-height: 1;
        font-weight: 500;
        color: red;
        display: flex;
        align-items: center;
        margin-right: 8px;
        .flash-icon{
            margin-left: 4px;
            color: red;
            font-size: 1.6rem;
            
        }
    }
    
}
.flash-date{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.flash-date-item{
    width: 25%;
    height: 133px;
    text-align: center;
    background-color: #444b52;
    
    cursor: pointer;
    border-right: 1px solid rgba(255,255,255,0.2);
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: #fff;
    // margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 57px;
    &:hover{
        cursor: pointer;
    }
    
}
.flash-date-item:first-child{
    border-top-left-radius:6px ;
}
.flash-date-item:last-child{
    border-top-right-radius:6px ;
}
.flash-date-active{
    background-color: #10cad8;
}

.flash-sale-date{
    display: flex;
    p{
        margin-left: 8px;
        padding: 4px 8px;
        background-color: #fff;
        border-radius: 1000px;
        color: #32373d;
        font-size: 1.6rem;
        line-height: 1rem;
        font-weight: 700;
        display: flex;
        align-items: center;
    }
}
.flash-sale-date-text{
    margin-top: 4px;
    font-size: 1.5rem;
    font-weight: 500;
}
// product
.flash-product{
   width: 100%;
   height: 362px;
}
.product{
    padding: 12px;
    width: 100%;
    // background-color:pink;
    // height: 342px;
    height: 100%;
    border: solid 1px white;

    &:hover{
        .product-image{
            transform: scale(1.03);
            transition: all 0.4s ease;
        }
        border: solid 1px #dee2e6;
        box-shadow: 0 0 2px 0 #dee2e6;
        border-radius: 6px;
    }
    
}
.product-image{
    position: relative;
    width: 100%;
    background-color: var(--border-image-color);
    display: flex;
    justify-content: center;
    height: 214px;
    border-radius: 10px;
    border-top: 40px solid var(--border-image-color);
    border-left: 2px solid var(--border-image-color);
    border-right: 2px solid var(--border-image-color);
    border-bottom: 2px solid var(--border-image-color);
    transition: all .3s ease;
    
}


.title-sale-product{
    position: absolute;
    top: -34px;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
}
.p-image-body{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    align-items: center;
}
.p-image{
    
    height: 90%;
    object-fit: cover;
}

.product-sale{
    bottom: 10px;
    left: 0px;
    position: absolute;
    font-size: 13px;
    padding: 0 8px;
    line-height: 24px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: inline-block;
    background-color: var(--border-image-color);
    color: white;
}
// info
.product-info{
    width: 100%;
    margin-top: 8px;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.product-info-title{
    font-size: 1.7rem;
    font-weight: 600;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    &:hover{
        cursor: pointer;
        color: blue;
    }
}
.product-info-price{
    font-size: 1.7rem;
    line-height: 24px;
    font-weight: 500;
    color: #cb1c22;
    margin: 8px 0;
    span{
        margin-left: 8px;
        font-size: 14px;
        line-height: 20px;
        color: #939ca3;
        text-decoration: line-through;
    }
}
.product-info-btn{
    background-color: var(--border-image-color);
    color: #fff;
    margin-bottom: 8px;
    height: 26px;
    text-align: center;
    border-radius: 20px;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    display: flex;align-items: center;
    justify-content: center;
}
@media (max-width: 800px) {
    .flash-date{
        // overflow-x: scroll;
        // flex-wrap: nowrap;
    }
    .flash-date-item{
        width: 50%!important;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 0;
    }
    .flash-date-item:last-child{
        border-radius: 0;
    }
}
@media (max-width: 692px) {
    
}
@media (max-width: 540px) {
    .flash{
        width: 100%;
        flex-direction: column;
        border-radius: 4px;
        h2{
            border-right: none;
            font-size: 2rem;
        }
        span{
            font-size: 1.6rem;
            margin-top: 6px;
        }
    }
    .flash-date{
        display: none;
    }
    
    .flash-product{
        margin-top: 40px;
    }
    .title-sale-product{
        font-size: 1.1rem;
        top: -30px;
    }
    .product-info-title{
        font-size: 1.3rem;
    }
    .product-info-price{
        font-size: 1.4rem;
    }
}
@media (max-width: 430px) {
    .flash-sale-date{
        p{
            display: none;
        }
    }
    
}