.m_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    display: flex;
    justify-content: flex-start;

    
}

@keyframes fadeIn {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(100%);
        opacity: 100%;
    }
}
.m_container{
    width: 80%;
    background-color: var(--bg-color);
    position: relative;
    box-shadow: 0 1px 5px rgba(236, 94, 11, 0.5);
    // animation: fadeIn linear 0.2s;
    display: flex;
    flex-direction: column;
    height: 100vh;
    animation:slideInLeft ease .5s forwards;
    // animation:slideOut ease .6s;
    transition: all .3s ease;
    // -prefix-animation: slideOut 1s ease 3.5s forwards;
}
@keyframes slideInLeft {
    from {
        opacity: 0.5;
        transform: translateX(calc(-100% ));
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(-100%);
    }
}
.m_header{
    background-color: #189e9e;
    padding: 10px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.image{
    width: 34px;height: 34px;
}
.logo_m{
    display: flex;
    margin-right: 6px;
}
.logo-link{
    font-size: 1.8rem;
    font-weight: 500;
    color: white;
}
.logo-cart{
    margin-left: 6px;
}
.icon-close {
    background-color: rgb(4, 133, 116);
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    transition: all .2s ease;
    &:hover {
        cursor: pointer;
        background-color: rgb(15, 155, 136);
        transform: scale(1.2);
    }
}
.m_body{
    display: flex;
    flex-direction: column;
    // padding: 10px 0px;
    // background-color: #edeeef;
}
.user-avatar{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    // margin-left: 12px;
    cursor: pointer;
    color: black;
}
.user-name{
    font-size: 1.6rem;
    font-weight: 500;
    margin-left: 6px;
    line-height: 25px;
}
.group{
    font-size: 1.6rem;
    background-color: white;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(27, 27, 27, 0.12);
    transition: all .3s ease;
    &:hover{
        cursor: pointer;
        background-color: rgba(141, 140, 140, 0.12);
        font-weight: 500;
    }
}
.group-name{
    border-bottom: 10px solid rgba(46, 45, 45, 0.12);
    
}
.group{
    a,p{
        display: flex;
        align-items: center;
    }
}
.item-icon{
    margin-right: 10px;
}
.btn-log{
    font-weight: 500;
    border-top: 10px solid rgba(46, 45, 45, 0.12);
}