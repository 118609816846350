
.banner{
    display: flex;
    justify-content: center;
    margin-top: var(--default-layout-header-height, 60px);
    background-image:url("https://fptshop.com.vn/dich-vu/Content/Desktop/images/banner-bg.png");
    margin-bottom: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100%;
    max-height: 300px;
    padding: 30px 0 10px;
}
.banner_wrapper{
    width: var(--default-layout-width);
    display: flex;
    gap: 50px;

}
.banner-image-body{
    width: 30%;
}
.banner-image{
    width: 100%;
}
.banner_contain{
    width: 70%;
    margin-top: 40px;
    color: white;
}
.banner-title{
    font-size: 3.4rem;
    line-height: 48px;
    font-weight: 500;
}
.banner-text{
    margin-top: 8px;
    font-size: 1.8rem;
    line-height: 28px;
}

.wrapper{
    background-color: var(--b-color);
    margin-top: 20px;
    // min-height: 500px;
    
    background-color: var(--b-color);
    display: flex;
    justify-content: center;
    padding: 20px 0 ;
    
}
.body{
    width: var(--default-layout-width);
}
.list{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}
.item{
    width: 32.2%;
    background-color: #fff;
    background-clip: border-box;
    -webkit-box-shadow: 0 0 3px 0 #dee2e6;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    &:hover{
        .item-body{
            // background: #cb1c22;
            background: var(--primary);
        }
        .item-icon{
            color: #fff;
        }
        .item-text{
            color: #302626; 
            font-weight: 500;
        }
    }
}
.item-body{
    border-radius: 50%;
    width: 88px;
    height: 88px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e1e4e6;
    transition: all .3s ease-in-out;
}
.item-icon{
    font-size: 3rem;
}
.item-text{
    font-size: 1.6rem;
    line-height: 24px;
    color: #444b52;
    font-weight: 400;
}
@media (max-width:1175px){
    .list{
        gap: 0;
    }
}
@media (max-width:692px){
    .banner{
        padding: 10px 8px 20px;
    }
    .banner_wrapper{
        gap: 4px;
        padding: 0 8px;
        text-align: center;
    }
    .banner_contain{
        margin-top: 0;
        width: 100%;
    }
    .banner-title{
        font-size: 2.2rem;
    }
    .banner-text{
        font-size: 1.2rem;
        margin-bottom: 20px;
    }
    .banner-image-body{
        display: none;
    }
    .wrapper{
        padding: 10px 8px;
        margin: 0;
    }
    .list{
        gap: 4px;
        justify-content: flex-start;
    }
    .item{
        width: 49%;
        padding:  4px;
    }
    .item-body{
        width: 44px;height: 44px;
        svg{
            font-size: 1.8rem;
        }
    }
    .item-text{
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
}