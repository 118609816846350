.wrapper{
    width: 100%;
    // height: 360px;
    background-color: white;
    display: flex;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    margin: 20px 0;
    // padding: 5px 5px 0 5px;
    gap:4px;
}
.sale-picture{
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
}
.m_sale-picture{
    display: none;
}
@media (max-width:692px){
    .sale-picture{
        display: none;
    }
    .m_sale-picture{
        display: block;
    }
    .wrapper{
        margin: 0;
    }
}
