.wrapper {
    display: flex;
    flex-direction: column;
    
    background-color: var(--b-color);
    align-items: center;
   
}
.container {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color);
    width: var(--default-layout-width);
    align-items: center;
    
}
.body{
    width: var(--default-layout-width);
    display: flex;
    height: 100%;
}
.content {
    width: 100%;
    position: relative;
    background-color: var(--b-color);
}
@media(max-width: 1225px) {
    .container{
        width: 100%;
        padding: 0 4px;
    }
    .body{
        width: 100%;
        padding: 0 2px;
    }
}
@media(max-width: 750px) {
    .container{
        margin: 0;
    }
}

@media (max-width: 740px) {
    .content {
        
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content.medium {
        margin-left: 80px;
    }
}
@media (max-width: 400px) {
    .content.medium {
        margin: auto;
    }
}
