.wrapper{
    width: 100%;
    height: 360px;
    background-color: white;
    display: flex;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    margin: 20px 0;
    padding: 5px 5px 0 5px;
    gap:4px;
}
.box-left{
    width: 60%;
    // background-color: red;
}
.box-right{
    width: 40%;
    display: flex;
    flex-direction: column;
    
    padding-left: 2px;
    
}
.body-item{
    color:#282c30 ;
    font-size: 1.3rem;
    text-align: center;
    border-bottom: 3px solid white;
    font-weight: 600;
    &:hover{
        cursor: pointer;
        border-bottom: 3px solid #0a8d95;
        color: black;
        font-weight: 600;

    }
}
.image-right{
    width: 100%;
    height: 30%;
    object-fit: cover;
    margin-bottom: 4px;
    border-radius: 4px;
}
.special-right{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 3px 0 8px;
    align-items: center;
    p{
        color: #6c757d;
        line-height: 1;
        font-weight: 600;
        margin-left: 8px;
    }
    span{
        color: #0168fa;
        font-weight: 400;
        &:hover{
            cursor: pointer;
        }
    }
}
.list-special{
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    margin-bottom: 8px;
    
}
.item-special{
    display: flex;
    align-items: flex-start;
    p{
        font-size: 1.5rem;
        font-weight: 700;
        
    }
    &:hover{
        cursor: pointer;
        .image-item{
            border: 1px solid red;
        }
    }
    
}
.image-item{
    border-radius: 8px;
    margin-right: 12px;
    padding: 1px;
    
    &:hover{
        border: 1px solid red;
    }

}
@media (max-width: 840px) {
    .wrapper{
        flex-direction: column;
        height: auto;
    }
    .box-left{
        width: 100%;
    }
    .box-right{
        width: 100%;
    }
    .image-right{
        display: none;
    }
}

@media (max-width: 620px) {
    
    .box-right{
        display: none;
    }
    .wrapper{
        display: none;
    }
}
@media (max-width: 500px) {
    
}

