.body {
    min-width: 300px;
    // height: 100vh;
    display: block;
    margin-top: 100px;
    // margin-bottom: 500px;
    background-color: var(--b-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container{
    width: var(--default-layout-width);
    // background-color: pink;
}

.back-to-top {
    display: none;
    width: 40px;
    height: 40px;
    background-color: var(--primary);
    position: fixed;
    bottom: 10px;
    right: 20px;
    color: var(--white);
    transform: rotate(-90deg);
    border-radius: 50%;
    animation: fadeOut 0.5s linear;

    &:hover {
        cursor: pointer;
        background-color: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #fe2c55;
    }
}
.active {
    display: block;
    animation: fadeIn 0.5s linear;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@media  (max-width: 1225px) {
    .body{
        padding: 0 6px;
    }
    .container{
        width: 100%;
    }
}
@media (min-width:561px) and (max-width: 850px){

}