.wrapper{
    width: 100%;
    height: 360px;
    background-color: white;
    display: flex;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    margin: 20px 0;
    // padding: 5px 5px 0 5px;
    gap:4px;
}
.container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.item{
    width: 20%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid rgba(0,0,0,.05);
    border-bottom: 1px solid rgba(0,0,0,.05);
    transition: all .3s ease;
    &:hover{
        border-color: rgba(0,0,0,.12);
        box-shadow: 0 0 10px 0 rgba(4, 233, 233, 0.15);
        cursor: pointer;
    }
}
.body-image{
    width: 95px;height: 95px;
    background-color: #f4f4f4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.link{
    flex-direction: column;
}
.image{
    width: 60px;height: 60px;
    object-fit: cover;}
.title{
    font-weight: 400;
    line-height: 1.3rem;
    text-align: center;
}
@media (max-width: 860px) {
    .item{
        width: 25%;
    }
    .wrapper{
        height: auto;
        
    }

}
@media (max-width: 620px) {
    .item{
        width: 50%;
    }
    .wrapper{
        height: 302px;
        overflow-y: scroll;
    }


}