
.header__cart-icon {
    color: rgb(158, 154, 154);
    color: white;
    font-size: 2.4rem;
    font-weight: 700;
    margin-top: 6px;
    margin-right: 18px;
    &:hover{
        color: antiquewhite;
    }
}
