.wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 700;
    min-width: 100px;
    padding: 9px 16px;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--white);
    border: 1px solid transparent;
    user-select: none;

    + .wrapper {
        margin-left: 8px;
    }

    &.disabled {
        cursor: no-drop;
        opacity: 0.5;
    }
}

.rounded {
    color: var(--bg-color-hover-btn-following);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 0 8px;
    min-width: 90px;
    height: 32px;
    box-shadow: var(--box-shadow);
    border-radius: 52px;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-color);
    background-color: var(--bg-color);

    &:hover {
        border-color: var(--bg-color-hover-btn-following);
        background-color: var(--bg-color-hover-btn-following);
    }
}

.primary {
    color: var(--text-color-black);
    background-color: var(--primary);
    border-color: var(--primary);

    &:hover {
        border-color: var(--primary);

        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #fe2c55;
    }
}

.outline {
    color: var(--primary);
    border-color: currentColor;

    &:hover {
        border-color: currentColor;

        background-color: rgba(254, 44, 85, 0.06);
    }
}

.text {
    &:hover {
        text-decoration: underline;
    }
}
// disabled

.small {
    padding: 4px 16px;
    min-width: 88px;
}
.large {
    padding: 14px 16px;
    min-width: 140px;
}
.icon + .title,
.title + .icon {
    padding-left: 8px;
}
.icon {
    display: inline-block;
    width: 20px;
    text-align: center;
}
