.wrapper{
    width: 100%;
    height: 100vh;
    background-color: var(--b-color);
    
    display: flex;
    flex-direction: column;
 
    
}
.header{
    height: 45px;
    width: 100%;
    background-color: rgb(4, 84, 204);
    padding: 2px 6px;
    color: white;
    display: flex;
    align-items: center;
    ul{
        display: flex;
        list-style: none;
        li{
            padding: 0 12px;
            line-height: 40px;
            // border: 2px solid rgb(235, 144, 8);
            margin-right: 10px;
            border-radius: 6px;
            &:hover{
                cursor: pointer;
                opacity: 0.9;
                
            }
        }
    }
}
.headerActive{
    color: rgb(255, 174, 0);
}
.container{
    flex: 1; 
    // display: flex;
    
}