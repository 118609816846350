.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes fadeIn {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(100%);
        opacity: 100%;
    }
}
.body {
    width: 480px;
    height: 80%;
    max-height: 693px;
    background-color: var(--bg-color);
    border-radius: 6px;
    position: relative;
    box-shadow: 0 1px 5px rgba(236, 94, 11, 0.5);
    margin: auto;
    animation: fadeIn linear 0.2s;
}
.back-icon {
    width: 40px;
    padding: 8px;
    height: 40px;
    margin: 4px 6px;
    color: var(--text-color-black);
    cursor: pointer;
}
.home-hiden {
    display: none;
}
.content {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
}
.close-icon {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 40px;
    padding: 8px;
    height: 40px;
    border-radius: 50%;
    color: var(--text-color-black);
    background: var(--bg-color-gray);
    text-align: center;
    line-height: 4rem;
    font-size: 1.2rem;
    z-index: 5;

    &:hover {
        cursor: pointer;
        color: var(--text-color-black);
        background: var(--bg-color-hover);
    }
}

.login-body {
    position: absolute;
    height: 80%;
    top: 44px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}
.title {
    font-size: 3.2rem;
    color: var(--text-color-black);
    font-weight: bold;
    width: 100%;
    margin: 12px auto 0px;
    padding-bottom: 18px;
    text-align: center;
}
.channel-item {
    color: var(--text-color-black);
    font-weight: 600;
    // border: 1px solid var(--border-color);
    font-size: 1.5rem;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    height: 44px;
    position: relative;
    cursor: pointer;
    margin-bottom: 16px;
    width: 375px;
    line-height: 4.4rem;
    align-items: center;

    &:hover {
        background-color: var(--bg-color-hover);
    }
    div{
        display: flex;
        align-items: center;
    }
}
.chanel-icon {
    position: absolute;
    left: 12px;
    font-size: 2rem;
}
.facebook-icon {
    color: rgb(7, 152, 248);
}
.instagram-icon {
    color: rgb(238, 107, 20);
}

.switch {
    color: var(--text-color-black);
    display: flex;
    justify-content: center;
    position: absolute;
    border-top: 1px solid var(--border-color);
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    line-height: 64px;
    height: 64px;
    overflow: hidden;
    background-color: var(--bg-color);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.switch-signup {
    color: red;
    margin-left: 5px;
    font-weight: 600;
    z-index: 20;
    background-color: var(--bg-color);

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
