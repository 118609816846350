.wrapper{
    
    width: 33.333%;
    
    
}
.product{
    padding: 12px;
    width: 100%;
    height: 490px;
    border: solid 1px white;
    &:hover{
        .product-image{
            transform: scale(1.03);
            transition: all 0.4s ease;
            cursor: pointer;
        }
        border: solid 1px #dee2e6;
        box-shadow: 0 0 2px 0 #dee2e6;
        border-radius: 6px;
        .product-info-btn{
            display: flex;
            transform: all 0.9s;
        }
    }
    
}
.product-image{
    position: relative;
    width: 100%;
    background-color: var(--border-image-color);
    display: flex;
    
    justify-content: center;
    height: 214px;
    border-radius: 10px;
    border-top: 40px solid var(--border-image-color);
    border-left: 2px solid var(--border-image-color);
    border-right: 2px solid var(--border-image-color);
    border-bottom: 2px solid var(--border-image-color);
    transition: all .3s ease;
    
}
.link{
    flex-direction: column;
}

.title-sale-product{
    position: absolute;
    top: -34px;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
}
.p-image-body{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    align-items: center;
}
.p-image{
    
    height: 90%;
    object-fit: cover;
}

.product-sale{
    bottom: 10px;
    left: 0px;
    position: absolute;
    font-size: 13px;
    padding: 0 8px;
    line-height: 24px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: inline-block;
    background-color: var(--border-image-color);
    color: white;
}
.product-pay{
    bottom: 42px;
    left: 0px;
    position: absolute;
    font-size: 13px;
    padding: 0 8px;
    line-height: 24px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: inline-block;
    background-color: #21aefa;
    color: white;
}
// info
.product-info{
    width: 100%;
    margin-top: 8px;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.product-info-title{
    font-size: 1.7rem;
    font-weight: 600;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    &:hover{
        cursor: pointer;
        color: blue;
    }
}
.product-info-price{
    font-size: 1.7rem;
    line-height: 24px;
    font-weight: 500;
    color: #cb1c22;
    margin: 8px 0;
    span{
        margin-left: 8px;
        font-size: 14px;
        line-height: 20px;
        color: #939ca3;
        text-decoration: line-through;
    }
}
.product-info-btn{
    background-color: var(--border-image-color);
    color: #fff;
    margin-bottom: 8px;
    height: 40px;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    display: none;
    align-items: center;
    justify-content: center;
}
// more info 
.more-info{
    background: #f8f9fa;
    padding: 9px 10px 0;
    border-radius: 6px;
    position: relative;
    font-size: 1.3rem;
    color: #6c757d;
}
.more-info-item{
    display: flex;
    // align-items: center;
    flex-direction: column;
    


    margin-bottom: 4px;
    span, p{
        display: flex;
        align-items: center;
        margin-right: 8px;
    }
    p,span{
        overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    }
}
.more-info-icon{
    margin-right: 4px;
}
.more-info-sale-up{
    margin: 6px 0;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.31;
    color: #555;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}
@media(max-width: 1125px) {
    .product{
        height: 510px;
    }
}
@media(max-width: 692px) {
    .wrapper{
        width: 49%;
    }
    .product{
        padding: 4px;
        height: 480px;
        &:hover{
            .product-image{
                transform: scale(1);
            }
        }
    }
    .title-sale-product{
        font-size: 1.4rem;
        top: -32px;
    }
    .product-info-title{
        font-size: 1.5rem;
    }
    .product-info-price{
        font-size: 1.5rem;
    }
   
}

