.wrapper{
    width: 75%;
    margin-bottom: 15px;
}
.contain{
    width: 100%;
    background-color: #fff;
    background-clip: border-box;
    -webkit-box-shadow: 0 0 3px 0 #dee2e6;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    padding: 15px 15px 0 15px;
    margin-bottom: 20px;
    // background-color: red;

}
.top{
    display: flex;
    gap: 20px;
}
.top_left{
    width: 55%;
}
.top_left-image-body{
    margin-bottom: 8px;
    cursor: pointer;

}
.top_left-image{
    width: 100%;
    object-fit: cover;
}
.top_left-text{
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 26px;
    max-height: 52px;
    overflow: hidden;
    display: block;
    margin-bottom: 3px;
    cursor: pointer;
}
.top_left-time{
    margin-bottom: 0;
    font-size: 1.4rem;
    line-height: 22px;
    font-weight: 300;
    color: #9b9b9b;
}



// right
.top_right{
    width: 45%;
    display: flex;
    flex-direction: column;
}
.top_item{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}
.top_item-image-body{
    width: 35%;
}
.top_item-image{
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}
.top_item-body{
    width: 65%;
}
.top_item-text{
    color: #3d3d3d;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    max-height: 60px;
    margin-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    cursor: pointer;
}
.top_right-time{
    color: #444b52;
    font-size: 1.2rem;
    line-height: 20px;
}
// card
.card{
    display: flex;
    flex-direction: column;
    
}
.card_item{
    display: flex;
    width: 100%;
    gap: 25px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dee2e6;
    cursor: pointer;
}
.card_item-image-body{
    width: 35%;
}
.card_item-image{
    width: 100%;
    object-fit: cover;
}
.card_item-info{
    width: 65%;
    display: flex;
    flex-direction: column;
}
.card_title{
    color: #0f71fb;
    margin-bottom: 4px;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
}
.card-special{
    font-size: 22px;
    line-height: 26px;
    max-height: 52px;
    font-weight: 700;
    overflow: hidden;
    margin-bottom: 8px;
}
.card-text{
    font-size: 14px;
    line-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-weight: 400;
    overflow: hidden;
    margin-bottom: 15px;
    color: #555;
}
.card_user{
    display: flex;
}
.card_user-image-body,.card_user-image{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.card-time{
    margin-left: 10px;
    font-size: 14px;
    line-height: 22px;
    margin-right: 5px;
    color: #9b9b9b;
}
// form
.form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-contain{
    padding: 25px 0px;
    background: #F8F9FA;
    border: 1px solid #E1E4E6;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: 0 15px 15px;
}
.form-title{
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #212529;
    margin: 0 auto;
}
.form-group{
    display: flex;
    margin: 10px 0;
    width: 100%;
    gap: 10px;
    align-items: center;
}
.radio-name{
    display: flex;
    align-items: flex-end;
    label{
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.2rem;
        margin: 0 1px 0 6px;
    }
}
.form-input{
    width: 100%;
    padding: 6px 12px;
    width: 256px;
    height: 32px;
    font-size: 1.4rem;
    background: #FFFFFF;
    border: 1px solid #CBD1D6;
    border-radius: 4px;
}
.form-input-email{
    width: 100%;
    
}
.radio-email{
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.2rem;
    text-align: center;
    color: #212529;
}
.form-group-email{
    margin: 20px 0;
}
.form-btn-body{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.form-btn{
    background: #CB1C22;
    color: white;
    cursor: pointer;
    border: none;
}
.form-phone{
    display: flex;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 100%;
    color: #444B52;
    margin-bottom: 15px;
    p{
        color: #CB1C22;
        margin: 0 5px ;
        cursor: pointer;
    }
}
@media (max-width:692px){
    .wrapper{
        width: 100%;
    }   
    .contain{
        padding: 10px 4px;
    }
    .top{
        flex-direction: column;
    }
    .top_left{
        width: 100%;
    }
    .top_left-text{
        font-size: 1.6rem;
    }
    .top_left-time{
        font-size: 1.2rem;
    }
    .top_right{
        width: 100%;
    }
    .card-special{
        font-size: 1.4rem;
    }
    .card_item{
        padding-bottom: 8px;
        margin-bottom: 8px;
    }
    .card-text{
        font-size: 1.2rem;
        margin-bottom: 8px;
    }
}