.wrapper{
        width: 100%;
        background-color: white;
        display: flex;
        box-shadow: 0 0 3px 0 #dee2e6;
        border-radius: 6px;
        margin: 20px 0 0;
        
        flex-direction: column;
        align-items: center;
        justify-content: center;
}
.container{
    width: var(--default-layout-width);
    // background-color: pink;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 4px ;
}
.one-column{
    width: 20%;
    padding: 8px 8px 8px 0;
    ul{
        list-style: none;
        display: inline-block;
        font-size: 1.3rem;
        color: #3399d2;
        
        li{
            margin-bottom: 10px;
            &:hover{
                cursor: pointer;
                text-decoration: underline;
                color: #0d2e41;
                
            }
        }
    }
}
.colum-title{
    font-size: 1.3rem;
    color: black;
    font-weight: 500;
}
.colum-phone{
    font-size: 20px;
    font-weight: 600;
    color: #d0021b;
    span{
        color: black;
        font-weight: 400;
        font-size: 1.3rem;
    }
}
.test{
    width: 80px;
    height: 36px;
    text-align: center;
    background-color: var(--primary);
    position: relative;
    border-radius: 6px;
    
}



.footer-end{
    width: 100%;
    background: #f2f2f2;
    font-size: 10px;
    color: #6d6d6d;
    text-align: center;
    padding: 10px 2px;
    clear: both;
}
@media (max-width: 1225px) {
    .container{width: 100%;padding: 0 6px;}
}
@media (max-width: 840px) {
    .one-column{
        width: 25%;
    }
}
@media (max-width: 760px) {
    .one-column{
        width: 50%;
    }
}
@media (max-width: 692px) {
    .one-column{
        text-align: center;
    }
    .wrapper{
        margin-top: 10px;
    }
}
