.header__navbar {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-weight: bold;

}
.user-avatar{
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 12px;
    cursor: pointer;
    color: black;
}
.user-name{
    font-size: 1.2rem;
    font-weight: 400;
    margin-left: 4px;
    line-height: 25px;
}
.header__navbar-list {
    list-style: none;
    padding-left: 0;
    
    display: flex;
}

.header__navbar-item {
    margin: 0 8px;
    position: relative;
    min-height: 26px;
    &:hover{
        .header__qr{
            display: block;
        }
    }
}
.header-noti{
    position: absolute;
    background-color: red;
    // padding:2px 4px;
    width: 20px;
    height: 20px;

    border-radius: 50%;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.3rem;
    top: -3px;
    left: -6px;
    border: 2px solid #0a8d95;
}


/* header navbar username */

.header__navbar-user {
    display: flex;
    justify-content: center;
    position: relative;
}
.facebook-icon{
    font-size: 1.5rem !important;
}
.header__navbar-user:hover .header__navbar-user-menu {
    display: block;
}

.header__navbar-user-img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid #f5f5f5;
}

.header__navbar-user-name {
    margin-left: 4px;
    font-size: 1.4rem;
    font-weight: 400;
}


/* menu */

.header__navbar-user-menu {
    list-style: none;
    position: absolute;
    z-index: 1;
    padding: 0px 0px;
    top: calc(100% + 6px);
    right: 0;
    background-color: var(--white-color);
    border-radius: 2px;
    width: 160px;
    box-shadow: 0 1px 2px #e0e0e0;
    display: none;
}

.header__navbar-user-menu::after {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    right: 0;
    width: 56%;
    height: 8px;
}

.header__navbar-user-menu::before {
    content: "";
    position: absolute;
    border-width: 12px 20px;
    border-style: solid;
    border-color: transparent transparent var(--white-color) transparent;
    right: 4px;
    top: -22px;
}

.header__navbar-user-menu-item-saparate {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.header__navbar-user-menu-item a {
    text-decoration: none;
    color: var(--text-color);
    font-size: 1.4rem;
    padding: 8px 16px;
    display: block;
    font-weight: 400;
}

.header__navbar-user-menu-item a:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.header__navbar-user-menu-item a:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.header__navbar-user-menu-item a:hover {
    background-color: var(--text-hover);
}

.header__navbar-item--has-notify:hover .header__notify {
    display: block;
    
    background-color: white;
    box-shadow: 1px 2px 2px rgba(22,24,24,0.12);
}

.header__navbar-item,
.header__navbar-item-link {
    display: inline-block;
    font-size: 1.3rem;
    color: var(--white-color);
    text-decoration: none;
    font-weight: 300;
}

.header__navbar-item,
.header__navbar-item-link,
.header__navbar-icon-link {
    display: inline-flex;
    align-items: center;
}

.header__navbar-item--strong {
    font-weight: 400;
}

.separate {
    display: inline;
    border-right: 1px solid rgb(248, 175, 175);
    height: 12px;
    margin-top: 7px;
}

.header__navbar-item:hover,
.header__navbar-item-link:hover,
.header__navbar-icon-link:hover {
    cursor: pointer;
    opacity: 0.9;
}

.header__navbar-icon-link {
    color: var(--white-color);
    text-decoration: none;
}

.header__navbar-icon-link:nth-child(2) {
    margin-left: 3px;
}

.header__navbar-icon {
    font-size: 1.8rem;
    margin: 0 4px;
}

.header__navbar-title--no-pointer {
    cursor: text;
    color: var(--white-color);
}


/* header Qr Code */

.header__qr {
    background-color: white;
    width: 180px;
    position: absolute;
    left: 0;
    top: 115%;
    padding: 8px;
    border-radius: 2px;
    display: none;
    animation: fadeIn ease-in .3s;
    z-index: 1;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.12);
}

.header__qr::before {
    position: absolute;
    width: 100%;
    height: 18%;
    left: 0;
    top: -18px;
    content: "";
    display: block;
}

.header__qr-img {
    width: 100%;
}

.header__qr-apps {
    display: flex;
    margin-left: 0;
    justify-content: space-between;
}

.header__qr-download-img {
    height: 16px;
}

.header__qr-link {
    margin-left: 0;
}

.header__qr-link:nth-child(1) {
    margin-left: 11px;
}

.header__qr-link:nth-child(2) {
    margin-right: 11px;
}

.header__navbar-item--has-qr:hover .header__qr {
    display: block;
}




@keyframes headerNotifyGrowth {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

// action
.icon-language{
    font-size: 1.6rem;
}
@media (max-width: 560px) {
    .header__navbar-item--has-qr,.separate,.help{
        display: none;
    }
    .content{
        padding: 0 4px;
    }
    .header__navbar{
        display: none;
    }
}
@media (max-width: 694px) {
    .display-none{
        display: none;
    }
    .search{
        width: 100%;
    }
}


