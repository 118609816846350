.wrapper{
    background-color: var(--b-color);
    margin-top: 10px;
    
}
.head-box{
    width: 100%;
    background-color: pink;
    margin-bottom: 15px;
    background-color: #fff;
    background-clip: border-box;
    -webkit-box-shadow: 0 0 3px 0 #dee2e6;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    padding: 15px;
}
.card-header{
    display: flex;
    margin-bottom: 0;
    background-color: #fff;
    border-radius: 6px;
    align-items: center;
    margin: 0;
    font-size: 2.4rem;
    font-weight: 600;
    
   p{
    color: #6c757d;
    margin-left: 10px;
    font-size: 18px;
    line-height: 2;
    
   }
}
.list-tag{
    display: inline-block;
    vertical-align: middle;
    
    font-size: 16px;
    color: #495057;
    display: flex;
    margin-top: 8px;
    p{
        margin-right: 10px;
    }
    ul{
        list-style: none;
        display: flex;
        li{
            font-size: inherit;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 1px;
            align-items: center;
            height: 23px;
            line-height: 23px;
            font-size: 12px;
            padding: 0 8px 0 8px;
            color: #8392a5;
            border-radius: 4px;
            border: solid 1px #b4bdce;
            margin: 0 10px 6px 0;
            cursor: pointer;
            display: flex;
            .icon-tag{
                margin-left: 4px;
            }

            &:hover{
                border: solid 1px #ea9d02;
                color: #ea9d02;
            }
        
        }
    }
}
// sỏrt
.product-box{
    width: 100%;  
    margin-bottom: 20px;
    background-color: #fff;
    background-clip: border-box;
    -webkit-box-shadow: 0 0 3px 0 #dee2e6;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    padding-top: 5px;
    
}
.sort-box{
    padding: 15px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}
.sort-title{
    font-size: 14px;
    line-height: 14px;
    color: #555;
    margin-right: 20px;
    display: inline-block;
}
.sort-list{
    display: flex;
}
.sort-list-item{
    color: #8392a5;
    border: solid 1px #ced4da;
    cursor: pointer;
    font-size: 1.3rem;
    padding: 4px 10px ;
    display: flex;
    align-items: center;
}
.sort-list-item:first-child{
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}
.sort-list-item:last-child{
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}
.sort-active{
    background-color: #1588e7;
    color: white;
}
.list-product{
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
}
// btn
.div-btn{
    width: 100%;
    
    display: flex;
    justify-content: center;
    padding:15px 0 30px ;
}
.btn{
    color: #8392a5;
    background: #e9ecef;
    &:hover{
        font-weight: 700;
        color: #8392a5;    
        background: #dee2e6;
    }
}
.no-product{
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    color: #888;
}
// go to top
.back-to-top {
    display: none;
    width: 40px;
    height: 40px;
    background-color: var(--primary);
    position: fixed;
    bottom: 10px;
    right: 20px;
    color: var(--white);
    transform: rotate(-90deg);
    border-radius: 50%;
    animation: fadeOut 0.5s linear;

    &:hover {
        cursor: pointer;
        background-color: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #fe2c55;
    }
}
.active {
    display: block;
    animation: fadeIn 0.5s linear;
}

@media(max-width: 692px) {
    .wrapper{
        width: 100%;
    }
    .head-box{
        margin: 0;
        padding: 6px 8px;
    }
    .card-header{
        font-size: 1.6rem;
    }
    .sort-box{display: none;}
    .list-product{
        justify-content: space-between;
    }
    .btn{
        font-size: 1.3rem;
    }
}
