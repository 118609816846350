.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    
}
@keyframes fadeIn {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(100%);
        opacity: 100%;
    }
}
.container{
    width: 1200px;
    background-color: var(--bg-color);
    border-radius: 6px;
    position: relative;
    box-shadow: 0 1px 5px rgba(236, 94, 11, 0.5);
    margin: auto;
    animation: fadeIn linear 0.2s;
    display: flex;
    flex-direction: column;
    height: 80vh;
}
.header {
    padding: 12px 24px;
    font-weight: 500;
    font-size: 1.8rem;
    display: flex;
    justify-content: space-between;
    color: var(--text-color-black);
    border-bottom: 0.5px solid var(--border-color);
}

.icon-close {
    background-color: var(--bg-color-hover);
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        background-color: var(--border-color);
    }
}
// body
.body-detail{
    display: flex;
    flex-wrap: wrap;
}
.group-title{
    margin: 10px 0px;
    display: flex;
    width: 50%;
    border-bottom: 1px solid greenyellow;
    padding: 5px 0;
    input{
        font-size: 1.6rem;
    }
    select{
        font-size: 1.5rem;
    }
}
.text-tile{
    color: blue;
    font-weight: 500;
    margin-right: 8px;
}
.input{
    // width: 48%;
    // padding: 10px 10px;
    // margin: 20px 4px;
    // border: 1px solid #888;
    // border-radius: 6px;
}
.body{
    width: 100%;
    padding: 10px 24px;
    overflow-y: scroll;
    
}

.footer{
    padding: 24px 0;
}
.footer-btn{
    width: 100%;
    
    display: flex;
    justify-content: center;
    margin-top: 6px;
}
.btn-finish{
    color: #fff;
    background: #cb1c22;
    padding: 14px 20px;
    &:hover{
        opacity: 0.9;
    }
}
.footer-end{
    padding: 24px 0 0;
    font-size: 1.4rem;
    text-align: center;
    color: #888;
    span{
        text-decoration: underline;
        cursor: pointer;
        &:hover{
            color: blue;
        }
    
    }
}
.group-brand{
    display: flex;
    flex-direction: column;
    width: 50%;
    

}
.brand-body{
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}
.brand-input{
    width: 100%;
    padding:  0 4px;
}