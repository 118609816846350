.menu-list {
    width: 200px;

    .menu-item {
        margin-left: 0;
    }
}

.menu-popper {
    padding-bottom: 8px;
    background-color: white;
    color: black;
}
.menu-body {
    overflow-y: overlay;
    font-size: 1.4rem;
}
// menu item
.menu-item {
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    font-weight: 600;
    padding: 11px 16px;
    line-height: 1.8rem;
    background-color: white;
    font-size: 1.3rem;

    &.separate {
        border-top: 1px solid var(--border-color);
    }

    &:hover {
        background-color: rgba(225, 225, 225, 0.3);
    }
}
// header
.header {
    position: relative;
    height: 50px;
    margin-top: -8px;
    flex-shrink: 0;
}

.back-btn {
    width: 50px;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
}

.header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
