@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600;700&display=swap');

:root {
    // --primary: green;

    // default layout
    --default-layout-header-height: 100px;
    --default-layout-width: 1200px;
    --default-layout-horizontal-spacer: 24px;
    --width-sidebar: 330px;
    // -------------
    --header-b-color:#02faf9;
    --white:white;
    --primary: #02faf9;
    --b-color:#f8f9fa;
    --border-image-color:#0a8d95;
    

    // ------------
    // mode
    --test-color: #3b3be7;

    
    --bg-color: white;
    --bg-color-hover: rgba(22, 24, 35, 0.03);
    --bg-gray: #f1f1f2;
    --bg-color-btn-fl: #fe2c55;
    --bg-color-hover-btn-fl: #fe2c550f;
    --bg-color-btn-following: white;
    --bg-color-hover-btn-following: #1618230a;
    --bg-modal-color: rgba(0, 0, 0, 0.5);
    --bg-color-sidebar: rgba(231, 228, 228, 0.1);

    --box-shadow-color: rgba(22, 24, 26, 0.5);

    --text-color-black: black;
    --text-color-white: white;
    --text-color-gray: #2f3035bf;

    --tick-color: #20d5ec;
    --border-color: #1618231a;
    --box-shadow: 0px 1px 1px rgb(0 0 0 / 12%);
    --box-shadow-share: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);
    --scroll-color: rgba(0, 0, 0, 0.15);
    --transparent-color: transparent;
    // mode

    --black: #000;
    --white: #fff;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    font-size: 70%;
}

body {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
    overflow-y: overlay;
    background-color: var(--bg-color-white);
}
html body {
    overflow-y: overlay;
}
// srollbar css
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--scroll-color);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: var(--scroll-color);
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}
a {
    color: var(--text-color);
    text-decoration: none;
}

// custem tippy tolltip
body.tippy-box {
    border-radius: 8px;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.2rem;
    background-color: var(--bg-color);
}
body .tippy-content {
    padding: 8px 9px;
}
