
.banner{
    display: flex;
    justify-content: center;
    margin-top: var(--default-layout-header-height, 60px);
    background-image: linear-gradient(rgb(120, 204, 243),rgb(117, 181, 211),rgb(131, 202, 235));
    // margin-bottom: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100%;
    // max-height: 300px;
    padding: 30px 0 30px;
}
.banner_wrapper{
    width: var(--default-layout-width);
    display: flex;
    flex-direction: column;
    align-items: center;

}
.top-title{
    font-size: 36px;
    font-weight: 400;
    line-height: 59px;
    color: #fff;
    text-align: center;
}
.top-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 2px;
    margin-top: 30px;
    background: #fff;
    border-radius: 4px;
    width: 75%;
}
.top-input-body{
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.top-input{
    width: 100%;
    padding: 6px;
    font-size: 20px;
    line-height: 23px;
    color: rgba(0,0,0,.87);
}
.top-icon-body{
    padding: 8px 20px;
    transition: all 0.5s ease;
    &:hover{
        opacity: 0.4rem;
        color: rgb(36, 34, 34);
        cursor: pointer;
        transform: scale(1.2);

    }
}
.top-icon{
    font-size: 3rem;
}

.wrapper{
    background-color: var(--b-color);
    background-color: var(--b-color);
    display: flex;
    justify-content: center;
    padding: 20px 0 ;
    
}
.body{
    width: var(--default-layout-width);
}
// menu
.help-menu{
    display: flex;
    flex-direction: column;
}
.help-title{
    padding: 40px 0 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(0,0,0,.87);
}
.menu-body{

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}
.menu-item{
    width: 24%;
    display: flex;
    align-items: center;
    float: left;
    // width: 238px;
    height: 72px;
    overflow: hidden;
    padding: 15px 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background: #fff;
    border: 1px solid rgba(0,0,0,.16);
}
.menu-image-body,.menu-image{
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 50%;
}
.menu-text{
    width: 140px;
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
// question
.question-list{
    display: flex;
    flex-direction: column;
}
.question-item{
    padding: 18px 10px;
    font-size: 1.8rem;
    line-height: 28px;
    cursor: pointer;
    border-bottom: 1px dashed #e1e1e1;
    transition: all 0.3s ease;
    &:hover{
        background-color: #f1eeee;
    }
}
.question-item:nth-child(1){
    border-top: 1px dashed #e1e1e1;
}
// more
.more{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0 16px;
}
.more-title{
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
}
.more-body{
    display: flex;
    justify-content: center;
}
.more-item{
    display: flex;
    align-items: center;
    width: 260px;
    height: 64px;
    padding: 12px;
    margin: 0 10px 24px;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    transition: all .3s ease-in-out;
    &:hover{
        background-color: rgba(0,0,0,.04);
        cursor: pointer;
    }
}
.more-icon-body{
    margin-right: 12px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,.16);
}
.more-icon{
    font-size: 2rem;
}
@media (max-width:968px){
    .menu-item{
        width: 30%;
    }
}
@media (max-width:692px){
    .banner_wrapper{
        padding: 0px 8px;
    }
    .top-title{
        font-size: 2rem;
        line-height: 2.2rem;
    }
    .top-body{
        width: 100%;
    }
    .help-title{
        font-size: 1.6rem;
        
        padding: 10px 8px;
    }
    .menu-body{
        gap: 8px;
    }
    .body{
        width: 100%;
        padding: 0 8px;
    }
    .menu-item{
        width: 48%;
        padding: 4px 4px;
        margin: 0;
    }
    .menu-text{
        font-size: 1.2rem;
    }
    .menu-image-body{
        margin-right: 4px;

    }
    .menu-image{
        width: 32px;height: 32px;
    }
    .question-item{
        font-size: 1.4rem;
        padding: 10px;
    }
    .more{
        padding: 18px 0;
    }
    .more-title{
        font-size: 1.6rem;
    }
    .more-body{
        justify-content: space-around;
        width: 100%;
    }
    .more-item{
        width: 49%;
        margin: 0;
        display: flex;
        justify-content: center;
    }
    .more-text{
        font-size: 1.4rem;
    }
    .more-icon-body{
        margin-right: 4px;
        width: 32px;height: 32px;
        svg{
            font-size: 1.6rem;
        }
    }
    .top-input-body{
        padding: 0 6px;
        input{
            font-size: 1.4rem;
           
        }
    }
    .top-body{
        height: 40px;
        margin-top: 10px;
    }
    .top-icon-body{
        svg{
            font-size: 2rem;
        }
    }
}