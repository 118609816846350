@media (max-width: 740px) {
    .search {
        display: none;
    }
}
.dark-mode {
    background-color: var(--bg-color);
    color: var(--text-color-black);
}

.wrapper {
    --search-border-radius: 4px;
    --search-height: 46px;
    --search-top-spacer: 9px;
    --search-button-width: 52px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--default-layout-header-height, 60px);
    box-shadow: var(--box-shadow);
    background-color: var(--header-b-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-image: linear-gradient(#189e9e,#0bc9c9,#02faf9) ;
    
}
.navigate{
    display: flex;
    justify-content: center;
    height: 35px;
    // background-color: #0a8d95;
    
    
    width: 100%;
    color: white;
    
    .nav-content{
        width: var(--default-layout-width);
        padding: 0 var(--default-layout-horizontal-spacer);
        display: flex;
        justify-content: space-between;
    }
}


.inner {
    height: 65%;
    width: var(--default-layout-width);
    padding: 0 var(--default-layout-horizontal-spacer);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}
.logo-link {
    display: flex;
}
.image {
    
    width: 98px;
    height: 55px;
    object-fit: contain;
    color: blue;

}
.search{
    width: 100%;
   display: flex;
   justify-content: center;
    
}
.actions {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.actions-noti{
    position: absolute;
    top: -4px;
    right: 4px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 26px;
    font-size: 13px;
    height: 24px;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    border: 3px solid var(--primary);
}
// news
.header_news{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    position: relative;
}
.header_news-icon-body{
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    &:hover{
        color: rgb(240, 237, 237);
    }
}
.header_news-icon{
    font-size: 2.8rem;
    margin-bottom: 0;
}
.header_news-text{
    display: none;
}
.header_news{
    // color: red;
    &:hover{
        .header_news-wrapper{
            display: block;
            // transition: all 3s ease-in;
        }
    }
}
.header_news-wrapper{
    display: none;
    position: absolute;
    background-color: black;
    // left: -152px;
    left: 0px;
    top: 68px;
    width: 200px;
    padding: 8px 20px;
    background: #fff;
    border: solid 1px #d3d3d3;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    transition: all 3s ease;
    transform: translateY(-20px);
}
@keyframes fadeIn{
    from{
        opacity: 0;
    }to{
        opacity: 1;
    }
}
.header_news-wrapper::before{
    position: absolute;
    content: "";
    width: 50px;
    height: 40px;
    top: -30px;
    left: 0px;
}
.header_news-title{
    font-size: 1.4rem;
    line-height: 2.43;
    color: #2e2d2d;
    font-weight: 500;
}
.header_news-item{
    // text-align: end;
    font-size: 14px;
    line-height: 2.43;
    color: #494949;
    &:hover{
        cursor: pointer;
        color: #070707;
    }
}

// news


.more-btn {
    padding: 8px;
    margin-left: 16px;
    font-size: 2rem;
    background-color: var(--transparent-color);
    cursor: pointer;
}

.action-btn {
    position: relative;
    color: var(--text-color-black);
    background-color: transparent;
    font-size: 2.2rem;
    padding: 4px 10px;
    height: 100%;
    cursor: pointer;
}

.badge {
    position: absolute;
    background-color: #fe2c55;
    border-radius: 10px;
    font-size: 1.4rem;
    right: -2px;
    top: -3px;
    color: white;
    line-height: 2rem;
    font-weight: 600;
    padding: 0 6px;
}

.user-avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 12px;
    cursor: pointer;
} 
.message-icon {
    width: 25px;
}
.btn-login {
    color: white;
}
.cart-responsive{
    display: none;
}
// mobile
.nav_m,.logo_m{
    display: none;
}

@media (max-width: 694px) {
    .wrapper{
        padding: 10px 4px;
        background-color: red;
    }
    .display-none{
        display: none;
    }
    .nav_m{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 6px 0px;
        padding: 0 6px;
    }
    .logo_m{
        display: flex;
        align-items: center;
    }
    .m_menu{
        margin-right: 6px;
        display: flex;
        align-items: flex-end;
    }
    .m_menu-icon{
        font-size: 3rem;
        line-height: 3rem;
        color: #fff;
        font-weight: 700;
    }
    .logo-link{
        font-size: 2.2rem;
        color: white;
        font-weight: 500;
        line-height: 3rem;
    }
    .actions_m{
        display: block!important;
    }
    .actions-noti{
        font-size: .8rem;
        width: 18px;
        height: 18px;
        top: 3px;
        right: 11px;
        border: none;
    }
    
}
@media (max-width: 1300px) {
    .header_news-wrapper{
        left: -80px;
    }
    .inner{
        gap: 30px;
    }
}
@media (max-width: 980px){
    .search{
        width: 100%;
    }
}
@media (max-width: 1225px) {
    .inner{
        width: 100%;
    }
    .header_news-wrapper{
        left: -120px;
    }
    // .search{
    //     max-width: 80%;
    // }
}

@media (max-width: 560px) {
    .wrapper{
        height: auto;
        padding: 0 0 10px;
    }
    .nav-content{
        padding: 0 4px !important;
        width: 100%;
    }
    .navigate,.actions{
        display: none;
    }
    .cart-responsive{
        display: block;
    }
    // .logo-cart{
    //     width: 100%;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    // }
    .inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .search{
        width: 100%!important;
    }
    
}
