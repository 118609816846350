.search,
.search-result {
    // box-shadow: 0 0 4px var(--primary);
    width: 500px;
    height: 60%;
    // overflow-y: scroll;
}
.search-tippy{
    width: 100%;
}
.search-result {
    box-shadow: 0px 0px 2px var(--primary);
    width: 500px;
    height: 60%;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 60%;
    overflow-y: scroll;
    background-color: white;
    
}
.search-title {
    color: var(--text-color-gray);
    font-size: 1.4rem;
    font-weight: 600;
    padding: 5px 12px;
    width: 700px;
}
.search {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: var(--search-height);
    padding-left: 16px;
    
    background-color: var(--white);
    border-radius: var(--search-border-radius);
    border: 1.5px solid transparent;
    width: 80%;
    // background-color: red;
    
    input {
        height: 100%;
        padding: 12px 40px 12px 0;
        color: var(--text-color-black);
        font-size: 1.4rem;
        background-color: transparent;
        flex: 1;
        caret-color: red;
    }
    .search-btn {
        margin: 0;
        background-color: var(--bg-gray);
        // background-color: blue;
    }
    input:not(:placeholder-shown) ~ .search-btn {
        color: var(--text-color-gray);
        background-color: var(--bg-gray);
    }

    &::before {
        content: '';
        position: absolute;
        height: calc(var(--search-height) - var(--search-top-spacer) * 2);
        width: 1px;
        top: var(--search-top-spacer);
        right: var(--search-button-width);
        background-color: var(--border-color);
    }

    &:focus-within {
        border-color: var(--border-color);
    }
}
.icon {
    background-color: var(--bg-gray);
    
}

.clear,
.loading {
    position: absolute;
    right: calc(var(--search-button-width) + 16px);
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-color-gray);
}

.loading {
    animation: spinner 0.5s linear infinite;
}

@keyframes spinner {
    from {
        transform: translateY(-50%) rotate(0deg);
    }
    to {
        transform: translateY(-50%) rotate(360deg);
    }
}

.search-btn {
    width: var(--search-button-width);
    height: 100%;
    border-top-right-radius: var(--search-border-radius);
    border-bottom-right-radius: var(--search-border-radius);
    font-size: 1.9rem;
    color: var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        // background-color: white;
        color:gray;
        cursor: pointer;
    }

    &:active {
        background-color: white;
    }
}
@media (max-width: 1200px) {
    .search{
        width: 100%;
        margin: 0 6px;
    }
}
@media (max-width: 694px) {
    .search{
        height: 40px;
        margin: 0 8px;
    }
    
}