.wrapper {
    width: 100%;
    max-height: min((100vh - 96px) - 60px, 734px);
    // max-height: cal(100vh- 385px);
    border-radius: 8px;
    background: var(--bg-color);
    box-shadow: rgb(0 0 0 / 12%) 0px 2px 12px;
    padding-top: 8px;
    // overflow-y: scroll;
    display: flex;
    flex-direction: column;
    
}
