.nav{
    width: 100%;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 13%);
    border-radius: 4px;
    display: flex;
    div{
        padding: 16px 0;
        font-size: 1.6rem;
        color: black;
        border-bottom: 2px solid rgba(0,0,0,.09);
        width: 25%;
        text-align: center;
        &:hover{
            cursor: pointer;
            color: #ee4d2d;
        }
    }
    .nav-active{
        color: #ee4d2d;
        border-bottom: 2px solid #ee4d2d;
    }
    
}
.right-body{
    width: 100%;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 13%);
    border-radius: 4px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    overflow-y: scroll;
}
.right-product{
    width: 100%;
    height: 100%;
    
}
.product-item{
    margin-top: 10px;
    display: flex;
    background-color: rgb(235, 234, 234);
    align-items: center;
    padding: 6px;
    width: 100%;
    
    &:hover{
        cursor: pointer;
        opacity: 0.8;
        .cancel{
            display: block;
        }
    }
}
.cancel{
    display: none;
    font-size: 1.6rem;
    font-weight: 400;
    background-color: white;
    padding: 6px 10px;
    &:hover{
        cursor: pointer;
        background-color: #c6d4e0;
    }
}
.product-image{
    width: 58px;
    height: 48px;
    border-radius: 2px;
    margin: 0 10px;
    object-fit: cover;
}
.product-info{
    flex: 1;
    p{
        font-weight: 600;
    }
    span{
        font-size: 1.4rem;
        color: #888;
    }
}
.format-date{
    // margin: 4px 0 0 0px;
  
    padding-left: 18px;
    border-top: 4px solid rgba(20, 210, 235, 0.6);
    font-style: italic;
    
}
@media (max-width:692px){
    .right-body{
        overflow: hidden;
    }
    .nav{
        div{
            font-size: 1.4rem;
            padding: 10px 0;
        }
    }
    .format-date{
        font-size: 1.4rem;
    }
    .product-info{
        p{
            font-size: 1.4rem;
        }
    }
    .product-image{
        margin-left: 0;
    }
    .cancel{
        display: block;
        font-size: 1.2rem;
        padding: 3px 6px;
    }
}