.wrapper{
    width: 100%;
    // height: 360px;
    background-color: white;
    display: flex;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    margin: 20px 0;
    // padding: 5px 5px 0 5px;
    gap:4px;
}
.container{
    width: 100%;
    padding: 20px 10px 10px;
}

.title{
    font-size: 1.9rem;
    line-height: 1;
    font-weight: 600;
    color: #212529;
    margin-bottom: 14px;
    text-transform: uppercase;
}
.banner-image{
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
}
.list-brand{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5px;
}
.brand-item{
    width: 19%;   
    border-radius: 6px;
    display: flex;flex-direction: column;
    align-items: center;
    border: 1px solid #acffff;
    :hover{
        cursor: pointer;
    }
}
.brand-btn-body{
    // position: relative;
    width: 100%;
}
.brand-btn{
    width: 120px;height: 40px;
    box-shadow: 0 1px 4px 0 #0a0a0a26;
    border-radius: 6px;
    transform: translateX(-50%);
    position: absolute;
    top: -34px;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    color: #46464d;
    background-color: white;
    z-index: 1;
    &:hover{
        opacity: 0.9;
    }
    
}
.brand-sale{
    font-size: 16px;
    line-height: 24px;
    color: #cb1c22;  
    margin-top: 46px;
    width: 100%;
    height: 52px;
    padding-top: 16px;
    text-align: center;
    background-color: #f7dbdc;
    position: relative;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    &::after{
        content: "";
        position: absolute;
        top: -26px;
        left: 0px;
        border-width:26px 107px 0;
        border-style: solid;
        border-color:transparent #f7dbdc  #f7dbdc #f7dbdc;
    }
}

@media (max-width: 1155px) {
    .list-brand{
        justify-content: center;
    }
    .brand-item{
        width: 30%;
        margin: 4px 6px;
    }
}
@media (max-width: 740px) {
    .brand-item{
        width: 46%;
        margin: 8px 6px;
    }
}
@media (max-width: 692px){
    .container{
        padding: 10px 0;
    }
    .title{
        font-size: 1.4rem;
        padding: 10px 4px 0px;
    }
    .brand-btn{
        font-size: 1.3rem;
    }

}
.m_list-brand{
    display: none;
}
@media (max-width: 500px){
    .list-brand{
        flex-wrap:nowrap !important;
        overflow-x: scroll;
        display: none;
    }
    .m_list-brand{
        display: flex;
        
    }
    .m_list-brand-body{
        .swiper-wrapper{
            display: flex;
            justify-content: space-between;
            gap: 10px;
        }
       
    }
    .brand-item{
        width: 100%;
        margin: 0 10px 0 0;
    }
    .brand-sale::after{
        display: none;
        border-width: 32px 107px 0;
    }
}