.wrapper {
    width: 300px;
    background: var(--b-color);
    padding-right: 30px;
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 10px rgb(252, 248, 248);
    }
    &:hover {
        &::-webkit-scrollbar {
            display: block;
            width: 6px;
            height: 800px;
        }
    }
}
.container{
    width: 100%;
    
}
.group{
    width: 100%;
    
    padding-top: 20px;
}
.label{
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #212529;
    margin-bottom: 21px;
}

.check-item{
    display: flex;
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;
    cursor: pointer;
    width: 100%;
    margin-bottom: 14px;
    p{
        margin-left: 10px;
    }
}

input[type='checkbox'] {
    position: relative;
    cursor: pointer;
}
input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    left: 0;
    border: 1px solid #c0cbcc;
    border-radius: 3px;
    background-color: white;
}

input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    border: 2px solid red;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    left: 5px;
}
@media(max-width: 1024px) {
    .wrapper{display: none;}
}