.wrapper{
    margin-top: 100px;
  
    
    display: flex;
    justify-content: center;
    background-color: var(--b-color);
    min-height: 100vh;
    
}
.container{
    width: var(--default-layout-width);
   
    display: flex;
    padding: 0 2px;
    margin-top: 20px;
    height: 80vh;
}
.left{
    width: 20%;
    background-color: var(--b-color);
}

.info{
    display: flex;
    margin: 20px 0;
    
}
.avatar{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
    object-fit: cover;
    
}
.name{
    font-size: 1.4rem;
    font-weight: 600;
}
.info-icon{
    display: flex;
    flex-direction: column;
}
.edit{
    display: flex;
    align-items: center;
    color: #888;
    font-size: 1.4rem;
    &:hover{
        cursor: pointer;
        opacity: 0.9;
    }
}
.list-title{
    font-size: 1.4rem;
    display: flex;align-items: center;
    margin:10px 0 ;
    &:hover{
        cursor: pointer;
        color: orangered;
        .list-title-icon{
            color: orangered;
        }
    }
}
.list-title-icon{
    color: #5584be;
    font-size: 1.8rem;
    margin-right: 6px;
}
.icon-active{
    color: orangered !important;
    .list-title-icon{
        color: orangered;
    }
}
.right{
   width: 80%;
    
}
.right-develop{
    width: 100%;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 13%);
    border-radius: 4px;
    height: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.right-image-develop{
    width: 180px;
    height: 180px;
    object-fit: cover;
}
@media (max-width:692px){
    .wrapper{
        padding: 0 8px;
    }
    .container{
        flex-direction: column;
        margin: 0;
       
    }
    .left{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 8px;
    }
    
    .list-title{
        width: 100%;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        span{
            text-align: center;
        }

    }
    .list-title{
        margin: 0;
    }
    .list-title-icon{
        margin: 0;
    }
    .list-title-body{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        
    }
    .info{
        width: 100%;
        margin: 8px 0;
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(22,24,24,0.12);
    }
    .info-icon{
        // width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    .edit{
        margin-left: 20px;
    }
    // right
    .right{
        width: 100%;
    }
}
