.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    
}
@keyframes fadeIn {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(100%);
        opacity: 100%;
    }
}
.container{
    width: 700px;
    background-color: var(--bg-color);
    border-radius: 6px;
    position: relative;
    box-shadow: 0 1px 5px rgba(236, 94, 11, 0.5);
    margin: auto;
    animation: fadeIn linear 0.2s;
    display: flex;
    flex-direction: column;
    height: 80vh;
}
.header {
    padding: 12px 24px;
    font-weight: 500;
    font-size: 1.8rem;
    display: flex;
    justify-content: space-between;
    color: var(--text-color-black);
    border-bottom: 0.5px solid var(--border-color);
}
.icon-close {
    background-color: var(--bg-color-hover);
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        background-color: var(--border-color);
    }
}
// body
.body{
    width: 100%;
    padding: 10px 24px;
    overflow-y: scroll;
    
}
.item{
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    border-bottom: 1px solid rgb(59, 235, 248);
}
.item-image{
    width: 15%;
}
.no-product{
    width: 100%;
    text-align: center;
    font-weight: 600;
}
.image-product{
    width: 80px;
    height: 80px;
    object-fit: cover;
}
.info-product{
    flex: 1;
}
.name-product{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #32373d;
    &:hover{
        cursor: pointer;
        color: blue;
    }
}
.check-invalid{
    color: red;
    margin-top: 10px;
    font-size: 1.4rem;
}
.detail-product{
    margin-top: 8px;
    padding-left: 16px;
    padding-right: 20px;
    margin-bottom: 4px;
    color: #6a737a;
    font-size: 1.3rem;
}
.info-right-product{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    p{
        color: #cb1c22;
        font-weight: 500;
        font-size: 1.5rem;
    }
    span{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #939ca3;
        text-align: center;
        cursor: pointer;
        
        transition: all .4s ease;
        .icon-trash{
            margin-right: 8px;
            transition: all .4s ease;

        }
        &:hover{
            color: #52585c;
        }
    }
}
// code sale
.sale-code{
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    
}
.sale-left{
    width: 50%;
}
.label-sale{
    font-size: 16px;
    line-height: 24px;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    font-weight: 500;
    color: #32373d;
    margin-bottom: 8px;
}
.sale-code-input{
    display: flex;
    input{
        width: 200px;
        height: 28px;
        color: #444b52;
        font-size: 14px;
        border: 1px solid #ccc;
        padding: 0 6px;
        border-radius: 4px;
    }
}
.sale-btn{
    margin-left: 8px;
    color: #fff;
    background: #cb1c22;
    font-size: 14px;
    line-height: 14px;
    padding: 7px 12px;
    &:hover{
        background: #ae1427;
    }
}
.sale-right{
    width: 50%;
    padding: 0 10px 0 30px;
    
}
.price-total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    font-size: 1.2rem;
}
.pay-total{
    display: flex;
    font-size: 1.4rem;
    font-weight: 500;
    justify-content: space-between;
    span{
        color: red;
    }
}
// form use ìno
.info-user{
    border-top: 1px solid rgb(18, 214, 240);
    border-bottom: 1px solid rgb(18, 214, 240);
    padding: 12px 0;
}
.info-user-title{
    font-size: 1.6rem;
    margin-bottom: 10px;
    
}
.info-user-form{
    width: 100%;
    

}
.info-user-top{
    display: flex;justify-content: space-between;
    gap:10px;
    margin-bottom: 8px;
}
.info-user-input{
    border: 1px solid #ccc;
    width: 100%;
    padding: 8px 10px;
    font-size: 1.3rem;
    border-radius: 6px;

}
.footer{
    padding: 24px 0;
}
.footer-btn{
    width: 100%;
    
    display: flex;
    justify-content: center;
    margin-top: 6px;
    align-items: center;
}
.btn-finish{
    color: #fff;
    background: #cb1c22!important;
    padding: 14px 20px;
    display: flex;
    align-items: center;
    &:hover{
        opacity: 0.9;
    }
}
.footer-end{
    padding: 24px 0 0;
    font-size: 1.4rem;
    text-align: center;
    color: #888;
    span{
        text-decoration: underline;
        cursor: pointer;
        &:hover{
            color: blue;
        }
    
    }
}
.m_icon-close{
    display: none;
}
@media (max-width: 692px) {
    #modal-buy{
        // overflow: scroll;
    }
    .wrapper{
        padding: 0;
        overflow-y: overlay;
        display: block;
    }
    .container{
        margin: 0;
        min-height: 2000px;
    }
    
    .m_hidden{
        display: none;
    }
    .m_icon-close{
        display: flex;
        margin-right: 4px;
    }
    .header{
        padding: 12px 8px;
        justify-content: flex-start;
    }
    .body{
        padding: 10px 8px;
        overflow-y: scroll;
    }
    .item{
        margin-bottom: 8px;
    }
    .footer{
        padding-bottom: 0;
    }
    .footer-end{
        font-size: 1.2rem;
    }
}
@media (max-width: 620px) {
    .container{
        width: 100%;
        margin: 0 ;
    }
    .sale-code{
        flex-direction: column;
    }
    .sale-left{
        width: 100%;
    }
    .sale-right{
        width: 100%;
        padding: 20px 0 0 0;
    }
}
@media (max-width: 580px) {
    .info-product{
        display: none;
    }.item{
        justify-content: space-between;
    }
}