.wrapper{
    width: 100%;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 13%);
    border-radius: 4px;
    height: 100%;
    padding: 10px 20px;
    // margin-top: 20px;
}
.title{
    div{
        font-size: 1.8rem;
        font-weight: 500;
        color: #333;
    }
    p{
        font-size: 1.2rem;
    }
    border-bottom: 1px solid rgba(24,24,25, 0.12);
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.body{
    width: 100%;
    display: flex;

}
.left{
    width: 65%;
    
}
.right{
    width: 35%;
   display: flex;
   flex-direction: column;
   align-items: center;
   p{
    font-size: 1rem;
    color: #999;
    margin: 20px 0;
   }
}
.image{
    width: 100px;height: 100px;
    border-radius: 50%;
    margin: 20px 0;
    object-fit: cover;
}
.btn-image{
    outline: 0;
    background: #fff;
    color: #555;
    border: 1px solid rgba(0,0,0,.09);
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 3%);
    position: relative;
    overflow: visible;
    padding: 6px 15px;
    cursor: pointer;
    border-radius: 4px;
    &:hover{
        background: rgba(0,0,0,.02);
    }
}

.item{
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    padding: 0 20px;
    div{
        color: rgba(85,85,85,.8);
        font-size: 1.4rem;
        width: 35%;
        // margin-left: 20px;
    }
    .input{
        border: 1px solid rgba(24,24,25,0.12);
        width: 65%;
        height: 80%;
        border-radius: 6px;
        input{
            width: 100%;
            height: 100%;
            color: rgba(85,85,85,.8);
            font-size: 1.6rem;
            border-radius: 6px;
            padding: 0 10px;
            line-height: 1.6rem;
        }
    }
    p{
        color: rgba(85,85,85,1);
        font-size: 1.6rem;
    }
}
.btn{
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.btn-save{
    
    &:hover{
        background-color: rgba(127, 160, 231, 0.6);
    }
}
// input upload
.input-upload{
    display: none;
}
@media (max-width:692px){
    .wrapper{
        height: 100vh;
        padding: 10px 0;
        background-color: default;
    }
    .body{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    .title{
        padding: 10px 8px;
        text-align: center;
        margin: 0;
        div{

            font-size: 1.6rem;
        }
    }
    .right{
        width: 100%;
        p{
            margin: 10px 0 20px;
        }
    }
    .image{
        margin: 10px 0;
    }
    .left{
        width: 100%;
    }
    .item{
        padding: 0 6px;
        div {
            font-size: 1.2rem;
            width: 30%;

        }
        p{
            font-size: 1.2rem;

        }
        .input{
            width: 70%;
            input{
                font-size: 1.4rem;
            }
        }
    }
    .btn-save{
        font-size: 1.4rem;
    }
    .btn{
        margin-top: 10px;
    }
}