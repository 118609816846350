.wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
}
.icon{
    position: absolute;
    right: 0;
    font-size: 3rem;
    margin: 5px 10px;
    border-radius: 50%;
    padding: 5px;
    &:hover{
        cursor: pointer;
        background-color: #2ad6e2;
        color: #3c3e3f;
    }
    
}
.left{
    width: 50%;
    // background-color: pink;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}
.left-body{
// background-color: pink;
width: 500px;
margin: auto;
height: 100vh;
}
.left-logo{
    padding-top: 36px;
    display: flex;
    align-items: center;
    p{
        font-weight: 500;
        font-style: italic;
    }
}
.left-logo-image{
    width: 30px;
    margin-right: 6px;
}
.left-title{
    margin: 42px 0;
    font-size: 3.2rem;
    font-weight: 600;
    text-align: center;
}
.left-group{
    margin-top: 20px;
}
.div-label{
    font-weight: 600;
    font-size: 1.4rem;
}
.input{
    appearance: none;
    background-color: #f3f3f5;
    border: 1px solid #bcc0c4;
    
    border-radius: 8px;
    display: block;
    font-size: 1.5rem;
    margin-top: 8px;
    outline: none;
    padding: 10px;
    width: 100%;
}
.invalid {
    border-color: red !important;
    // color: var(--primary);
}
.form-message {
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding-left: 8px;
    color: red;
}
.left-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 40px 0 20px;
    .submit-btn{
        width: 100%;
        border-radius: 8px;
        &:hover{
            color: white;
        }
    }
}
.left-forget{
    color: red;
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
}
.left-privacy{
    font-size: 1.1rem;
    text-align: center;
    margin-top: 40px;
    span{
        color: red;
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
.right{
    width: 50%;
    background-color: red;
    .right-image{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
@media (max-width:692px){
    .right{
        display: none;
    }
    .left{
        width: 100%;
    }
}