.wrapper{
    width: 25%;
    margin-bottom: 15px;
}
.contain{
    width: 100%;
    background-color: #fff;
    background-clip: border-box;
    -webkit-box-shadow: 0 0 3px 0 #dee2e6;
    box-shadow: 0 0 3px 0 #dee2e6;
    border-radius: 6px;
    padding: 15px ;
    margin-bottom: 20px;

}
// top
.top-title{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-bottom:  15px;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,.125);
    font-weight: 500;
    font-size: 1.6rem;
}
.top-list{
    display: flex;
    flex-direction: column;
}
.top-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
}
.top-num{
    width: 40px;
    height: 34px;
    background: #99a2aa;
    border-radius: 50%;
    margin-right: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-num-active{
    background: #cb1c22;
}
.top-text{
    font-size: 1.4rem;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 5px;
    max-height: 36px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
}
// card
.card-item{
    display: flex;
    margin-top: 20px;
    cursor: pointer;
}
.card-image-body,.card-image{
    margin-right: 10px;
    width: 80px;
    height: 80px;
}
.card-info-title{
    line-height: 18px;
    margin-bottom: 4px;
    margin-top: 3px;
    font-weight: 400;
    font-size: 16px;
    max-height: 36px;
    overflow: hidden;
    display: block;
    color: #3d3d3d;
}
.card-info-post{
    color: #0f71fb;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    display: block;
}
@media (max-width:692px){
    .wrapper{
        display: none;
    }   
}