.wrapper{
    margin-top: 20px;
    width: 100%;
    // background-color: var(--b-color);
    display: flex;
    justify-content: center;
}
.container{
    background-color: var(--bg-color);
    width: var(--default-layout-width);
    padding: 15px 20px;
    border-radius: 4px;
    border: solid 1px #e9ecef;
    background-color: #f8f9fa;
    margin-bottom: 16px;
}
.viewed-title{
    color: #212529;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}
.viewed-list{
    display: flex;
    flex-wrap: wrap;
}
.viewed-item{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.viewed-image-body,.viewed-image{
    width: 200px;
    height: 132px;
    background-color: transparent;
    object-fit: contain;
}
.viewed-name{
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    margin-top: 8px;
    font-size: 15px;
    line-height: 20px;
    transition: all .2s ease;
    &:hover{
        color: #0168fa;
    }
    
}
.viewed-info{
    padding: 0 20px;
    
}
.viewed-price{
    color: #cb1c22;
    font-size: 14px;
    font-weight: 500;
}
@media (max-width:692px){
    .container{
        padding: 10px 8px;
        margin: 0;
    }
    .viewed-title{
        font-size: 1.5rem;
        margin-bottom: 8px;
    }
    .viewed-list{
        display: flex;
        
    }
    .viewed-item{
        width: 50%;
    }
    .viewed-name{
        font-size: 1.1rem;
    }
}
