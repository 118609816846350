.wrapper{
    width: 320px;
    height: 450px;
    background-color: white;
    right: 20px;
    bottom: 0;
    position: fixed;
    z-index: 999;
    border-radius: 6px;
    padding: 4px 0 0px 0;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    border-color: #cfd1d3 #c7cbcf transparent #e9ecef;
    box-shadow: 1px 1px 6px rgba(24,24,25,0.6);
}
.header{
    height: 50px;width: 100%;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 2px solid rgba(24,24,25,0.12);
    
}
.header-info{
display: flex;
align-items: center;
p{
    color: rgb(5,5,5);
    font-size: 1.5rem;
    font-weight: 600;
}
}
.header-avatar{
    width: 32px;height: 32px;
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
}
.header-icon-close{
    font-size: 2.6rem;
   
    padding: 5px;
    border-radius: 50%;
    &:hover{
        cursor: pointer;
        color: black;
        font-size: 2.8rem;
    }
}
// body

.body{
    overflow-y: scroll;
    width: 100%;
    flex:1;
    .text-message{
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        padding: 0 4px 0 8px;
        width: 80%;
        
        .contain{
            display: flex;
            align-items: flex-end;
            .send-avatar{
                margin-right: 6px;
                .send-image{
                width: 28px;height: 28px;
                border-radius: 50%;
                object-fit: cover; 
                }
            }
            .send-text{
                font-size: 1.5rem;
                background-color: rgb(228,230,235);
                border-radius: 10px;
                padding: 8px;
            }
        }
        .send-time{
            width: 100%;
            font-size: 1.2rem;
            font-weight: 400;
            text-align: end;
            padding-right: 10px;

        }

    }
    .isOwn{
        width: 100%;
        align-items: flex-end;
        .contain{
            max-width: 80%;
           .send-avatar{
            display: none;
           }
           .send-text{
            background-color: var(--header-b-color);
           }
        }
        .send-time{
            padding-left: 10px;
            max-width: 80%;
            text-align: start !important;
        }
    }
}
// footer
.footer{
    height: 60px;
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid rgba(24,24,25,0.12);
    input{
        background-color: var(--b-color);
        width: 100%;
        height: 100%;
        border-radius: 20px;
        margin: 0 10px;
        padding: 0 15px;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .footer-icon-send{
        width: 24px;height: 24px;
        margin-right: 10px;
        cursor: pointer;
        color: rgb(28,30,33);
        font-weight: 400;
        &:hover{
            width: 26px;height: 26px;
        }
    }
}